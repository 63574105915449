import React, { Component, createRef } from "react";
import { Spinner, Dropdown, Modal, Button, Form, Tab, Tabs } from "react-bootstrap";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { EuiAccordion } from '@elastic/eui';
import { customReportType, translatedErrorMessages, translatedLableMessages, defaultReportType, reportAccessRoles, reportTypes } from '../../constant/Constants';
import { models } from 'powerbi-client';
import { insightRepEditStatus, activeInsightReport } from '../../redux/actions/headerActions';
import { setReportsUpdateStatus, setFetchReportsDataStatus } from '../../redux/actions/reportsActions';
import { duplicateInsightReport, deleteInsightReport, renameInsightReport } from '../../services/InsightService';
import { getFormattedUsername, getUserReportAccessRole, hasUserAdminAccess } from '../../utilities/Utils';
import { toast } from "react-toastify";
import { BiSolidDashboard } from "react-icons/bi";
import { MdBarChart, MdSupportAgent, MdEventNote } from "react-icons/md";
import { PiChartBarHorizontalFill } from "react-icons/pi";
import { FaEllipsisV } from 'react-icons/fa';
import { BsTrash } from "react-icons/bs";
import '../../styles/insightsview.scss';
import '../../styles/drawPolygon.scss';
import '../../styles/modal.scss';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { RiArrowLeftDoubleFill } from "react-icons/ri";
import { withTranslation } from 'react-i18next';

const reportIconColor = '#070707';
const activeReportIconClr = '#7800F7';
const toastId = "toast-id";
const duplicateAction = "Duplicate";
const renameAction = "Rename";
const deleteAction = "Delete";
const reportGrpTab = "Report Group";
const reportTab = "Report";
const reportGrpStates = {
  OPEN: 'open',
  CLOSED: 'closed'
}

class InsightReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      reportsList: [],
      showRenameReportModal: false,
      reportNameExist: false,
      newReportName: '',
      isNewRepNameEmpty: false,
      searchVal: '',
      showSpinner: false,
      showConfirmationModal: false,
      showDuplicateReportModal: false,
      pagesSelectedState: {},
      showSelectOnePage: false,
      selectAllPages: false,
      modalActiveTab: null,
      showDeleteReportModal: false,
      selectedPageToRename: null,
      selectOneRepRenameMsg: false,
    };
    this.activeReportId = null;
    this.activeReportPage = null;
    this.lastDeletedRepId = null;
    this.inactionReportID = null;
    this.reportAction = null;
    this.latestRenamedReport = null;
    this.pageToDelete = null;
    this.inactionReportData = null;
    this.pagesToDelete = [];
    this.newlyClonedReportId = null;
    this.selectedPageToRename = null;
    this.reportInprogressAction = null;
    this.inputRef = createRef();
    this.inputRef2 = createRef();
    this.userRepAccessRole = getUserReportAccessRole();
    this.isActivePageDeleted = false;
  }

  componentDidMount = () => {
    const { t } = this.props;
    this.showReportsList();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { reportsDataUpdated, reportLoadStatus, loadingReportId, reportTypeToLoad } = this.props;
    if (prevProps.reportsDataUpdated === false && reportsDataUpdated === true) {
      if (this.state.searchVal && this.state.searchVal !== "") {
        this.filterReportsList(true)
      } else {
        this.showReportsList();
      }
    }
    
    if (this.newlyClonedReportId && loadingReportId && loadingReportId == this.newlyClonedReportId && reportLoadStatus === true) {
      this.newlyClonedReportId = null;
      if (this.pagesToDelete && this.pagesToDelete.length > 0) {
        this.deleteReportPages(duplicateAction);
      } else {
        this.setState({showProgressModal: false})
      }
    }

    if (this.reportInprogressAction === deleteAction && loadingReportId && loadingReportId == this.inactionReportID && reportLoadStatus === true) {
      if (this.pagesToDelete && this.pagesToDelete.length > 0) this.deleteReportPages(deleteAction);
    }

    if (this.reportInprogressAction === renameAction && loadingReportId && loadingReportId == this.inactionReportID && reportLoadStatus === true) {
      this.renameSelectedPage(this.selectedPageToRename);
      this.selectedPageToRename = null;
    }

    if ((!prevProps.reportTypeToLoad && reportTypeToLoad) || (prevProps.reportTypeToLoad &&  reportTypeToLoad && prevProps.reportTypeToLoad != reportTypeToLoad)) {
      this.loadReportBasedOnType(reportTypeToLoad);
    }
  }

  showReportsList = () => {
    let reportsData = this.props.reportsData;
    if (reportsData && reportsData.length > 0) {
      reportsData = this.updateReportsListOrder(reportsData);
      reportsData = reportsData.filter(rep => rep.pages && rep.pages.length > 0);
      reportsData.map((report) => {
        report.selected = (report.powerbi_reportid === this.activeReportId);
        report.forceState = (report.powerbi_reportid === this.activeReportId) ? reportGrpStates.OPEN : reportGrpStates.CLOSED;
      });
      if (this.newlyClonedReportId && this.pagesToDelete && this.pagesToDelete.length > 0) {
        reportsData = this.filterUnselectedPages(reportsData);
      }
  
      this.setState({
        reportsList: reportsData 
      }, () => {
        this.props.setReportsUpdateStatus(false);
        if (this.newlyClonedReportId) {
          const reportIndex = reportsData.findIndex((rep) => rep.powerbi_reportid == this.newlyClonedReportId);
          this.handleReportSelect(this.state.reportsList[reportIndex]);
        } else if (!this.activeReportId) {
          if (this.props.activeInsightReport) {
            const activeReport = this.props.activeInsightReport;
            this.handleReportSelect(activeReport, activeReport.activePageData);
          } else {
            setTimeout(() => { this.handleReportSelect(this.state.reportsList[0]) }, 200);
          }
        } else if (this.activeReportId && this.activeReportId === this.lastDeletedRepId) {
          let reportToSelect = this.state.reportsList[0];
          if (this.latestRenamedReport) {
            const foundReport = this.state.reportsList.find((rep) => (rep.report_name == this.latestRenamedReport));
            if (foundReport) reportToSelect = foundReport;
            this.latestRenamedReport = null;
          }
          this.lastDeletedRepId = null;
          this.activeReportId = reportToSelect.powerbi_reportid;
          this.handleReportSelect(reportToSelect);
        } else if (this.isActivePageDeleted && this.activeReportId) {
          this.isActivePageDeleted = false;
          const reportIndex = reportsData.findIndex((rep) => rep.powerbi_reportid == this.activeReportId);
          if (reportIndex >= 0) {
            this.handleReportSelect(this.state.reportsList[reportIndex]);
          }
        }
      })
    }
  }

  /* To show the report of report_type = defaultReportType as first one in the list */
  updateReportsListOrder = (reportsData) => {
    const reports = reportsData.slice();
    const defRepIndex = reports.findIndex(e => e.report_type == defaultReportType);
    if (defRepIndex >= 0) {
      const defRepArr = reports.splice(defRepIndex, 1);
      return defRepArr.concat(reports);
    }
  }

  handleReportSelect = (selReport, page = null) => {
    if (!this.props.isOnline) return;
    this.props.hideReportView(true)
    if (this.activeReportId === selReport.powerbi_reportid && page && this.activeReportPage === page.Name) return;
    const reportsList = this.state.reportsList;
    if (reportsList) {
      this.activeReportId = null;
      this.activeReportPage = null;
      reportsList.map(report => {
        if (report.powerbi_reportid === selReport.powerbi_reportid) {
          report.reportMode = (this.reportInprogressAction || this.newlyClonedReportId) ? models.ViewMode.Edit : models.ViewMode.View;
          report.selected = true;
          report.activePageData = (page) ? page : report.pages[0];
          report.activePage = this.activeReportPage = (page) ? page.Name : report.pages[0].Name;
          report.forceState = reportGrpStates.OPEN;
          this.activeReportId = report.powerbi_reportid;
          this.props.setActiveInsightReport({...{}, ...report});
        } else {
          report.reportMode = models.ViewMode.View;
          report.selected = false;
          report.activePageData = null;
          report.activePage = null;
          report.forceState = reportGrpStates.CLOSED;
        }
      });
      if (!this.newlyClonedReportId) this.props.insightRepEditStatus(false);
      this.setState({ reportsList: reportsList })
    }
  }

  loadReportBasedOnType = (reportType) => {
    const reportsData = this.props.reportsData;
    const reportDataToLoad = reportsData.find(rep => rep.report_type == reportType);
    if (reportDataToLoad) {
      this.activeReportId = reportDataToLoad.powerbi_reportid;
      this.handleReportSelect(reportDataToLoad);
    }
  }

  cleanedReportName = (name) => (name.replace(/\s/g, '').toLowerCase());

  isValidReportName = (enteredName, objsList, nameKey) => {
    const enteredNameCleaned = this.cleanedReportName(enteredName);
    const existingNamesCleaned = objsList.map((obj) => this.cleanedReportName(obj[nameKey]));
    return !existingNamesCleaned.includes(enteredNameCleaned);
  }

  handleReportNameUpdate = (action) => {
    this.resetErrMsgs();
    if (this.state.newReportName && this.state.newReportName.trim() != '') {
      const newRepName = this.state.newReportName;
      if (this.isValidReportName(newRepName, this.props.reportsData, 'report_name')) {
        if (action === duplicateAction) {
          const selectedPagesCnt = ((Object.values(this.state.pagesSelectedState).find(val => val===true)) || []).length;
          if (selectedPagesCnt == 0) {
            this.setState({ showSelectOnePage: true });
          } else {
            this.reportInprogressAction = duplicateAction;
            this.duplicateReport(this.inactionReportID, newRepName);
            this.setState({ showDuplicateReportModal: false });
          }
        }
        else if (action === renameAction) {
          this.reportInprogressAction = renameAction;
          this.setState({ showProgressModal: true, showRenameReportModal: false });
          this.renameReport(this.inactionReportID, newRepName);
        }
      } else {
        this.setState({reportNameExist: true});
      }
    } else {
      this.setState({isNewRepNameEmpty: true});
    }
  }

  resetErrMsgs = () => {
    this.setState({isNewRepNameEmpty: false, reportNameExist: false});
  }

  showConfirmationModal = (reportId, pageName) => {
    this.inactionReportID = reportId;
    this.pageToDelete = pageName;
    this.setState({showConfirmationModal: true});
  }

  closeConfirmationModal = () => {
    this.inactionReportID = null;
    this.pageToDelete = null;
    this.setState({showConfirmationModal: false});
  }

  showRenameReportModal = (report, action) => {
    if (!this.props.isOnline) return;
    this.inactionReportData = report;
    this.inactionReportID = report.powerbi_reportid;
    this.reportAction = action;
    this.setState({showRenameReportModal: true, reportNameExist: false, isNewRepNameEmpty: false, modalActiveTab: reportGrpTab});
    setTimeout(() => { this.inputRef.current.focus() }, 100);
  }

  showDuplicateReportModal = (report) => {
    if (!this.props.isOnline) return;
    this.reportAction = duplicateAction;
    this.setReportActionData(report);
    this.setState({ showDuplicateReportModal: true });
    setTimeout(() => { this.inputRef.current.focus() }, 100);
  }

  showDeleteReportModal = (report) => {
    if (!this.props.isOnline) return;
    this.reportAction = deleteAction;
    this.setReportActionData(report);
    this.setState({ showDeleteReportModal: true });
  }

  setReportActionData = (report) => {
    this.inactionReportData = report;
    this.inactionReportID = report.powerbi_reportid;
    this.pagesToDelete = [];
    const pagesSelectedState = this.updatePagesCheckedState(report);
    this.setState({reportNameExist: false, isNewRepNameEmpty: false, showSelectOnePage: false, pagesSelectedState: pagesSelectedState, selectAllPages: false});
  }

  updatePagesCheckedState = (report) => {
    const reportsData = this.props.reportsData;
    const reportDataToClone = reportsData.find(rep => rep.powerbi_reportid == this.inactionReportData.powerbi_reportid);
    const pagesSelectedState = {};
    if (reportDataToClone) {
      reportDataToClone.pages.forEach(page => {
        const pageData = report.pages.find(pg => (pg.Name == page.Name));
        /* pagesSelectedState[page.Name] = (pageData) ? true : false; */
        pagesSelectedState[page.Name] = false;
      })
      return pagesSelectedState;
    }
    return {};
  }

  closeReportActionModal = () => {
    this.inactionReportID = null;
    this.inactionReportData = null;
    this.reportAction = null;
    this.reportInprogressAction = null;
    this.selectedPageToRename = null;
    this.setState({showDuplicateReportModal: false, showDeleteReportModal: false, showRenameReportModal: false, newReportName: '', reportNameExist: false, isNewRepNameEmpty: false, showSelectOnePage: false, selectOneRepRenameMsg: false, pagesSelectedState: {}});
  }

  handleRenameClick = () => {
    if (this.state.modalActiveTab === reportGrpTab) {
      this.handleReportNameUpdate(renameAction);
    } else if (this.state.modalActiveTab === reportTab) {
      if (this.state.newReportName && this.state.newReportName.trim() != '') {
        const newRepName = this.state.newReportName;
        const reportData = this.props.reportsData.find(rep => rep.powerbi_reportid == this.inactionReportID);
        if (this.isValidReportName(newRepName, reportData.pages, 'DisplayName')) {
          if (!this.selectedPageToRename) {
            this.setState({ selectOneRepRenameMsg: true, reportNameExist: false })
          } else {
            this.reportInprogressAction = renameAction;
            this.setState({ showProgressModal: true, showRenameReportModal: false });
            if (this.activeReportId === this.inactionReportID) {
              this.props.embeddedReportObj.switchMode(models.ViewMode.Edit);
              this.renameSelectedPage(this.selectedPageToRename);
            } else {
              this.handleReportSelect(this.inactionReportData);
            }
          }
        }
        else {
          this.setState({reportNameExist: true});
        }
      } else {
        this.setState({isNewRepNameEmpty: true});
      }
    }
  }

  renameSelectedPage = async (pageName) => {
    if (this.props.embeddedReportObj) {
      const { t } = this.props;
      try {
        // Rename the page
        await this.props.embeddedReportObj.renamePage(pageName, this.state.newReportName);
        console.log('--- rename page completed ---')
        await this.props.embeddedReportObj.save();
        this.setState({ showProgressModal: false });
        toast.success(t("successMessages.renameReportSuccess"), { toastId: toastId });
        this.closeReportActionModal();
      } catch (e) {
        // console.log('error: ', e);
      }
    }
  }

  duplicateReport = (reportID, reportName) => {
    const { t } = this.props;
    if (reportID && reportName) {
      this.setState({ showProgressModal: true })
      duplicateInsightReport(reportID, reportName).then(response => {
        if (response && response.status == 200 && response.data) {
          this.newlyClonedReportId = response.data.message.powerbi_reportid;
          const pagesSelectedState = this.state.pagesSelectedState;
          for (const pageName in pagesSelectedState) {
            if (!pagesSelectedState[pageName]) this.pagesToDelete.push(pageName);
          }
          // console.log('selectedPagesToDelete: ', this.pagesToDelete)
          this.closeReportActionModal();
          this.props.setFetchReportsDataStatus(true);
          if (this.pagesToDelete.length == 0) {
            this.newlyClonedReportId = null;
            this.setState({showProgressModal: false})
            toast.success(t("successMessages.duplicateReportSuccess"), { toastId: toastId });
          }
        } else {
          this.setState({showProgressModal: false})
          toast.error(translatedErrorMessages.duplicateReportError(), { toastId: toastId });
        }
      })
      .catch(error => {
        this.setState({showProgressModal: false})
        toast.error(translatedErrorMessages.duplicateReportError(), { toastId: toastId });
        console.log('error: ', error);
      });
    }
  }

  deleteReport = (reportID) => {
    const { t } = this.props;
    if (reportID) {
      deleteInsightReport(reportID).then(response => {
        if (response && response.status == 200) {
          this.lastDeletedRepId = reportID;
          this.closeReportActionModal();
          this.props.setFetchReportsDataStatus(true);
          this.setState({ showProgressModal: false });
          toast.success(t("successMessages.deleteReportSuccess"), { toastId: toastId });
        } else {
          this.setState({ showProgressModal: false });
          toast.error(translatedErrorMessages.deleteReportError(), { toastId: toastId });
        }
      })
      .catch(error => {
        this.setState({ showProgressModal: false });
        toast.error(translatedErrorMessages.deleteReportError(), { toastId: toastId });
        console.log('error: ', error);
      });
    }
  }

  renameReport = (reportID, reportName) => {
    const { t } = this.props;
    if (reportID && reportName) {
      renameInsightReport(reportID, reportName).then(response => {
        if (response && response.status == 200) {
          this.lastDeletedRepId = reportID;
          this.latestRenamedReport = reportName;
          this.closeReportActionModal();
          this.props.setFetchReportsDataStatus(true);
          this.setState({ showProgressModal: false });
          toast.success(t("successMessages.renameRepGrpSuccess"), { toastId: toastId });
        } else {
          this.setState({ showProgressModal: false });
          toast.error(translatedErrorMessages.renameReportError(), { toastId: toastId });
        }
      })
      .catch(error => {
        this.setState({ showProgressModal: false });
        toast.error(translatedErrorMessages.renameReportError(), { toastId: toastId });
        console.log('error: ', error);
      });
    }
  }

  filterUnselectedPages = (reportsData) => {
    if (this.pagesToDelete && this.pagesToDelete.length > 0) {
      const reportIndex = reportsData.findIndex((rep) => rep.powerbi_reportid == this.newlyClonedReportId);
      if (reportIndex) {
        this.pagesToDelete.forEach((pageName) => {
          const pageIndex = reportsData[reportIndex].pages.findIndex(page => page.Name == pageName);
          if (pageIndex >= 0) reportsData[reportIndex].pages.splice(pageIndex, 1);
        })
      }
      return reportsData;
    }
  }

  deleteReportPages = async (action) => {
    if (this.props.embeddedReportObj) {
      try {
        const { t } = this.props;
        const pagesToDelete = this.pagesToDelete;
        this.pagesToDelete = [];
        for(let i=0; i < pagesToDelete.length; i++) {
          await this.props.embeddedReportObj.deletePage(pagesToDelete[i]);
        }
        console.log('--- delete pages completed ---')
        await this.props.embeddedReportObj.save();
        if (action == deleteAction && pagesToDelete.includes(this.activeReportPage)) {
          this.isActivePageDeleted = true;
        }
        this.setState({ showProgressModal: false })
        const successMsg = (action == duplicateAction) ? t("successMessages.duplicateReportSuccess") : t("successMessages.deleteReportSuccess");
        toast.success(successMsg, { toastId: toastId });
        this.reportInprogressAction = null;
      } catch (e) {
        // console.log(e)
      }
    }
  }

  handleReportDelete = () => {
    const selectedPagesCnt = ((Object.values(this.state.pagesSelectedState).filter(val => val===true)) || []).length;
    const reportPagesLen = (this.inactionReportData.pages || []).length;
    if (selectedPagesCnt == 0) {
      this.setState({ showSelectOnePage: true });
    } else if (selectedPagesCnt == reportPagesLen) {
      this.reportInprogressAction = deleteAction;
      this.setState({ showProgressModal: true, showDeleteReportModal: false });
      this.deleteReport(this.inactionReportID);
    } else {
      this.reportInprogressAction = deleteAction;
      this.setState({ showProgressModal: true, showDeleteReportModal: false });
      const pagesSelectedState = this.state.pagesSelectedState;
      for (const pageName in pagesSelectedState) {
        if (pagesSelectedState[pageName]) this.pagesToDelete.push(pageName);
      }
      if (this.activeReportId === this.inactionReportID) {
        this.props.embeddedReportObj.switchMode(models.ViewMode.Edit);
        this.deleteReportPages(deleteAction);
      } else {
        this.handleReportSelect(this.inactionReportData);
      }
    }
  }

  reportsListItem = (report) => {
    return (
      <>
        <div className="insights-list-item d-flex">
          <div className="flex-shrink-0">
            {this.reportIcon(report)}
          </div>
          <div className="flex-grow-1">
            {this.reportHeaderExpandable(report)}
          </div>
          <div className="flex-shrink-0">
            {this.reportDropdownOptions(report)}
          </div>
        </div>
      </>
    )
  }

  reportIcon = (report) => {
    let reportIconClass = 'insight-report-icon';
    let repIconColor = reportIconColor;
    if (report.selected) {
      reportIconClass += ' active';
      repIconColor = activeReportIconClr;
    }
    const reportType = report.report_type;

    switch (reportType) {
      case reportTypes.DASHBOARD: {
        return (<span className={reportIconClass}><BiSolidDashboard size="18px" color={repIconColor} /></span>)
      }
      case reportTypes.STANDARD: {
        return (<span className={reportIconClass}><MdBarChart size="18px" color={repIconColor} /></span>)
      }
      case reportTypes.AGENT: {
        return (<span className={reportIconClass} style={{border: 0}}><MdSupportAgent size="26px" color={repIconColor} /></span>)
      }
      case reportTypes.EVENT: {
        return (<span className={reportIconClass} style={{border: 0}}><MdEventNote size="26px" color={repIconColor} /></span>)
      }
      default: {
        return (<span className={reportIconClass}><PiChartBarHorizontalFill size="18px" color={repIconColor} /></span>)
      }
    } 
  }

  reportHeaderExpandable = (report) => {
    let reportClass = 'insight-report-accordian';
    if (report.selected) reportClass += ' active-report';

    return (
      <>
        <EuiAccordion
          id={report.powerbi_reportid}
          arrowDisplay="none"
          paddingSize="none"
          buttonContent={report.report_name}
          onToggle={(isOpen) => this.handleClickOnRepGrp(isOpen, report)}
          forceState={report.forceState}
          className={reportClass}
        >
          {this.reportPagesList(report)}
        </EuiAccordion>
      </>
    )
  }

  handleClickOnRepGrp = (isOpen, selReportGrp) => {
    if (selReportGrp) {
      const reportsData = this.state.reportsList;
      let reportGrpToLoad = null;
      reportsData.map((reportGrp) => {
        reportGrp.forceState = reportGrpStates.CLOSED;
        if (reportGrp.powerbi_reportid === selReportGrp.powerbi_reportid) {
          reportGrp.forceState = isOpen ? reportGrpStates.OPEN : reportGrpStates.CLOSED;
          reportGrpToLoad = reportGrp;
        }
      });
      this.setState({
        reportsList: reportsData 
      }, () => {
        if (isOpen && reportGrpToLoad && reportGrpToLoad.powerbi_reportid !== this.activeReportId) {
          this.handleReportSelect(reportGrpToLoad);
        }
      });
    }
  }

  checkShowDeletePage = (report) => {
    return (report.report_type === customReportType && this.props.isEditReportOn && (this.activeReportId === report.powerbi_reportid))
  }

  reportPageClass = (report, page) => {
    let activeRepId = this.activeReportId;
    let activePage = this.activeReportPage;
    if (this.props.activeInsightReport && this.props.activeInsightReport.powerbi_reportid) {
      activeRepId = this.props.activeInsightReport.powerbi_reportid;
      activePage = this.props.activeInsightReport.activePage;
    }
    return (activeRepId === report.powerbi_reportid && activePage === page.Name) ? 'active' : '';
  }

  reportPagesList = (report) => {
    return (
      <>
        {
          report.pages && report.pages.length > 0 ? (
            report.pages.map((page, index) => {
              return (
                <div key={page.Name} className="d-flex justify-content-between align-items-start">
                  <div 
                    onClick={() => this.handleReportSelect(report, page)} 
                    className={`insight-report-page ${this.reportPageClass(report, page)}`}
                  >
                    {page.DisplayName}
                  </div>
                </div>
              )
            })
          ) : null
        }
      </>
    )
  }

  isContributor = () => (this.userRepAccessRole === reportAccessRoles.CONTRIBUTOR);

  checkDeleteOrRenameAccess = (report) => {
    if (report.report_type === customReportType && hasUserAdminAccess()) {
      return true;
    }

    return (this.isContributor() && report.report_type === customReportType && getFormattedUsername() == report.created_by);
  }

  reportDropdownOptions = (report) => {
    const deleteOrRenameAccess = this.checkDeleteOrRenameAccess(report);
    const disabledCls = deleteOrRenameAccess ? '' : 'disabled';

    return (
      <>
        <Dropdown drop="left" className="custom-dropdown">
          <Dropdown.Toggle>
            <FaEllipsisV className="hand-cursor"></FaEllipsisV>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {(this.isContributor() || hasUserAdminAccess()) ? (
              <Dropdown.Item onClick={() => this.showDuplicateReportModal(report)}>{translatedLableMessages.duplicate()}</Dropdown.Item>
            ) : (
              <Dropdown.Item className="disabled">{translatedLableMessages.duplicate()}</Dropdown.Item>
            )}

            { deleteOrRenameAccess ? (
                <>
                  <Dropdown.Item onClick={() => this.showRenameReportModal(report, renameAction)}>{translatedLableMessages.rename()}</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.showDeleteReportModal(report)}>{translatedLableMessages.delete()}</Dropdown.Item>
                </>
              ) : (
                <>
                  <Dropdown.Item className={disabledCls}>{translatedLableMessages.rename()}</Dropdown.Item>
                  <Dropdown.Item className={disabledCls}>{translatedLableMessages.delete()}</Dropdown.Item>
                </>
              )
            }
          </Dropdown.Menu>
        </Dropdown>
      </>
    )
  }

  reportNameContent = (inputRef) => {
    const placeholderTxt = (this.state.modalActiveTab === reportTab) ? translatedLableMessages.reportNameHint() : translatedLableMessages.reportGrpNameHint();

    return (
      <>
        <div className='text-left form-field position-relative'>
          <input 
            type='text' 
            name='newReportName'
            ref={inputRef}
            className='form-control polygon-name' 
            placeholder={placeholderTxt}
            value={this.state.newReportName}
            onChange={(e) => this.setState({newReportName: e.target.value})} 
          />
          {!this.state.newReportName && this.state.isNewRepNameEmpty ?
            (<div className='error-msg'>{translatedErrorMessages.reportNameEmpty()}</div>)
            : ''}
          {this.state.reportNameExist ?
            (<div className='error-msg'>{translatedErrorMessages.reportAlreadyExist()}</div>)
            : ''}
        </div>
      </>
    )
  }

  duplicateReportModal = () => {
    return (
      <>
        <Modal className="insights-modal-dialog duplicate-report-modal" backdrop={false} show={this.state.showDuplicateReportModal} onHide={this.closeReportActionModal}>
          <Modal.Header closeButton>{translatedLableMessages.duplicateReport()}</Modal.Header>
          <Modal.Body>
            <div className='right-content'>
              <div className='text-left form-field position-relative'>
                <input 
                  type='text' 
                  name='newReportName'
                  ref={this.inputRef}
                  className='form-control polygon-name' 
                  placeholder={translatedLableMessages.reportGrpNameHint()}
                  onChange={(e) => this.setState({newReportName: e.target.value})} 
                />
                {!this.state.newReportName && this.state.isNewRepNameEmpty ?
                  (<div className='error-msg'>{translatedErrorMessages.reportNameEmpty()}</div>)
                  : ''}
                {this.state.reportNameExist ?
                  (<div className='error-msg'>{translatedErrorMessages.reportAlreadyExist()}</div>)
                  : ''}
                {this.state.showSelectOnePage ? (<div className='error-msg'>{translatedErrorMessages.selectOnePage()}</div>) : ''}
              </div>
              <div className="pages-list-section">
                {this.pagesChecklist()}
              </div>
              <div className='clone-modal-btns'>
                <Button variant="light" onClick={this.closeReportActionModal}>{translatedLableMessages.cancel()}</Button>
                <Button variant="primary" onClick={() => this.handleReportNameUpdate(duplicateAction)}>{translatedLableMessages.duplicate()}</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  renameReportModal = () => {
    const { t } = this.props;
    return (
      <>
        <Modal className="insights-modal-dialog duplicate-report-modal" backdrop={false} show={this.state.showRenameReportModal} onHide={this.closeReportActionModal}>
          <Modal.Header closeButton>{translatedLableMessages.rename()}</Modal.Header>
          
          <Modal.Body>
            <div className='right-content'>
              <Tabs
                id="controlled-tab-example"
                activeKey={this.state.modalActiveTab || reportGrpTab}
                onSelect={(tab) => this.handleTabSelect(tab)}
                className="mb-3"
              >
                <Tab eventKey={reportGrpTab} title={translatedLableMessages.reportGroup()}>
                  {this.reportNameContent(this.inputRef)}
                </Tab>
                <Tab eventKey={reportTab} title={translatedLableMessages.report()}>
                  {this.reportNameContent(this.inputRef2)}
                  {this.state.selectOneRepRenameMsg ? (<div className='error-msg'>{translatedErrorMessages.selectOneToRename()}</div>) : ''}
                  {this.pagesRadioBtnList()}
                </Tab>
              </Tabs>
              <div className='clone-modal-btns'>
                <Button variant="light" onClick={this.closeReportActionModal}>{translatedLableMessages.cancel()}</Button>
                <Button variant="primary" onClick={this.handleRenameClick}>{translatedLableMessages.rename()}</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  deleteReportModal = () => {
    return (
      <>
        <Modal className="insights-modal-dialog duplicate-report-modal" backdrop={false} show={this.state.showDeleteReportModal} onHide={this.closeReportActionModal}>
          <Modal.Header closeButton>{translatedLableMessages.delete()}</Modal.Header>
          <Modal.Body>
            <div className='right-content'>
              <div className='text-left form-field grey-text position-relative'>
                * {translatedLableMessages.selectAllReportsToDelete()}
              </div>
              <div className='text-left form-field position-relative ml-5'>
                {this.state.showSelectOnePage ? (<div className='error-msg'>{translatedErrorMessages.selectOnePage()}</div>) : ''}
              </div>
              <div className="pages-list-section">
                {this.pagesChecklist()}
              </div>
              <div className='clone-modal-btns'>
                <Button variant="light" onClick={this.closeReportActionModal}>{translatedLableMessages.cancel()}</Button>
                <Button variant="primary" onClick={() => this.handleReportDelete()}>{translatedLableMessages.delete()}</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  handleTabSelect = (activeTab) => {
    this.setState({ modalActiveTab: activeTab, isNewRepNameEmpty: false, reportNameExist: false });
    setTimeout(() => {
      (activeTab == reportGrpTab) ? (this.inputRef.current.focus()) : (this.inputRef2.current.focus())
    }, 100)
  }

  handlePageCheck = (pageName) => {
    const pagesSelectedState = this.state.pagesSelectedState;
    pagesSelectedState[pageName] = !pagesSelectedState[pageName];
    this.setState({ pagesSelectedState: pagesSelectedState});
    if (pagesSelectedState[pageName]) {
      const selectedVals = Object.values(pagesSelectedState).filter((val) => val === true);
      if (Object.values(pagesSelectedState).length === selectedVals.length) this.setState({ selectAllPages: true });
      this.setState({ showSelectOnePage: false });
    } else {
      this.setState({ selectAllPages: false });
    }
  }

  selectAllPages = (e) => {
    const pagesStatus = this.state.pagesSelectedState;
    for (const page in pagesStatus) {
      pagesStatus[page] = e.target.checked;
    }
    this.setState({ pagesSelectedState: pagesStatus, selectAllPages: e.target.checked });
  }

  pagesChecklist = () => {
    let reportsData = this.props.reportsData;
    if (reportsData && this.inactionReportData) {
      const reportDataToClone = reportsData.find(rep => rep.powerbi_reportid == this.inactionReportData.powerbi_reportid);
      const pages = (reportDataToClone && reportDataToClone.pages) ? reportDataToClone.pages : null;
      if (pages && pages.length > 0) {
        return (
          <>
            <div className="ml-5">
              <Form.Check
                inline
                type="checkbox"
                label={translatedLableMessages.selectAll()}
                name="selectAll"
                id="selectAll"
                checked={this.state.selectAllPages}
                onChange={e => this.selectAllPages(e)}
              />
            </div>
            <ul className="pages-list">
            {
              pages.map((page, index) => {
                return (
                  <li key={index}>
                    <div className="pages-list-item">
                      <Form.Check
                        type="checkbox"
                        label={page.DisplayName}
                        name={page.Name}
                        id={page.Name}
                        checked={this.state.pagesSelectedState[page.Name]}
                        onChange={() => this.handlePageCheck(page.Name)}
                      />
                    </div>
                  </li>
                )
              })
            }
            </ul>
          </>
        )
      }
    }
    return null;
  }

  pagesRadioBtnList = () => {
    let reportsData = this.props.reportsData;
    if (reportsData && this.inactionReportData) {
      const reportData = reportsData.find(rep => rep.powerbi_reportid == this.inactionReportData.powerbi_reportid);
      const pages = (reportData && reportData.pages) ? reportData.pages : null;
      if (pages && pages.length > 0) {
        return (
          <>
            <ul className="pages-list">
            {
              pages.map((page, index) => {
                return (
                  <li key={index}>
                    <div className="pages-list-item">
                      <Form.Check
                        type="radio"
                        label={page.DisplayName}
                        name="pageToRename"
                        id={page.Name}
                        onChange={() => { this.handleRadioBtnSelect(page.Name) }}
                      />
                    </div>
                  </li>
                )
              })
            }
            </ul>
          </>
        )
      }
    }
    return null;
  }

  handleRadioBtnSelect = (pageName) => {
    this.selectedPageToRename = pageName;
    this.setState({ selectOneRepRenameMsg: false });
  }

  handleChange = (event) => {
    const trimmedValue = event.target.value;
    if (trimmedValue.length > 0) {
        this.setState({
          searchVal: trimmedValue
        }, () => {
          this.filterReportsList()
        })
    } else {
        this.clearReportSearch()
    }
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      if (event.target){
        event.target.blur();
      }
      if (this.state.reportsList && this.state.reportsList.length) {
        setTimeout(() => {
          this.handleReportSelect(this.state.reportsList[0]);
        }, 100);
      }
    }
  };

  filterReportsList = (triggerReportSelect = false) => {
    if (this.state.searchVal && this.state.searchVal.trim() !== "") {
      this.setState({
        showSpinner: true
      }, () => {
        this.props.setReportsUpdateStatus(false);
        let reportsData = this.props.reportsData;
        if (reportsData && reportsData.length > 0) {
          reportsData = this.updateReportsListOrder(reportsData);
          let filteredReports = reportsData.map(report => {
            report.selected = (report.powerbi_reportid === this.activeReportId);
            if (this.newlyClonedReportId && report.powerbi_reportid == this.newlyClonedReportId) return report; // skip filtering pages for new cloned report
            if (report.report_name.toLowerCase().includes(this.state.searchVal.trim().toLowerCase())) {
              return report; // Report name matches
            } else {
              const filteredPages = report.pages.filter(page =>
                page.DisplayName.toLowerCase().includes(this.state.searchVal.trim().toLowerCase())
              );
              return { ...report, pages: filteredPages };
            }
          }).filter(report => report.pages.length > 0);

          /* To filter out unselected pages while duplicating a report */
          if (this.newlyClonedReportId && this.pagesToDelete && this.pagesToDelete.length > 0) {
            filteredReports = this.filterUnselectedPages(filteredReports);
          }

          if (filteredReports && filteredReports.length) {
            this.setState({
              reportsList: filteredReports,
            }, () => {
              if (triggerReportSelect) {
                /* To render newly cloned report while duplicating a report */
                if (this.newlyClonedReportId) {
                  const report = reportsData.find((rep) => rep.powerbi_reportid == this.newlyClonedReportId);
                  if (report) this.handleReportSelect(report);
                } else if (this.activeReportId && this.activeReportId === this.lastDeletedRepId) {
                  this.handleReportSelect(filteredReports[0]);
                } else {
                  const isActiveRepInFilterRes = filteredReports.find(rep => rep.powerbi_reportid == this.activeReportId);
                  if (!isActiveRepInFilterRes) this.handleReportSelect(filteredReports[0]);
                }
              }
            })
          } else {
            this.setState({
              reportsList: []
            })
            this.props.hideReportView(false)
          }
          this.setState({
            showSpinner: false
          })
        } else {
          toast.dismiss();
          toast.warning(translatedErrorMessages.reportFilterEmptyData(), { toastId: toastId });
          this.setState({
            reportsList: []
          })
        }
      })
    } else {
      toast.dismiss();
      toast.warning(translatedErrorMessages.reportSearchEmptyError(), { toastId: toastId });
      this.setState({
        showSpinner: false
      })
    }
  }

  clearReportSearch = () => {
    this.setState({
      searchVal: "",
      showSpinner: false
    }, () => {
      this.props.hideReportView(true)
      this.showReportsList();
    })
  }
  
  confirmationModal = () => {
    return (
      <>
      <div className="clone-report">
        <Modal className="insights-modal-dialog" backdrop={false} show={this.state.showConfirmationModal}>
          <Modal.Body>
            <div className='right-content'>
              <div className='text-left form-field position-relative'>
                <div style={{marginTop: '25px'}}>Are you sure you want to delete this page?</div>
              </div>
              <div className='clone-modal-btns'>
                <Button variant="light" onClick={this.closeConfirmationModal}>{translatedLableMessages.cancel()}</Button>
                <Button variant="primary" onClick={() => this.deletePageofReport()}>{translatedLableMessages.ok()}</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      </>
    )
  }

  showProgressModal = () => {
    return (
      <>
      <div className="clone-report">
        <Modal className="progress-modal-dialog" backdrop={false} show={this.state.showProgressModal}>
          <Modal.Body>
            <div className='no-data-found'>
              <div>
                <Spinner animation="border" variant="primary">
                  <span className="sr-only"></span>
                </Spinner>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      </>
    )
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className='reports-panel'>
          <div className="report-search-bar">
              <input
                type="text"
                placeholder={translatedLableMessages.searchReport()}
                value={this.state.searchVal}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
              />
              {this.state.showSpinner ?
                <Spinner animation="border" variant="primary" size="sm" className="search-spinner" />
                : this.state.searchVal !== '' && !this.state.showSpinner ?
                  <FaTimes className='icon hand-cursor' onClick={() => { this.clearReportSearch() }}>close</FaTimes>
                :  <FaSearch className="icon" onClick={() => { this.filterReportsList() }}/>
              }
          </div>
          <div className='reports-list'>
            {
                this.state.reportsList && this.state.reportsList.length ? this.state.reportsList.map((report, index) => {
                    return (<div key={report.powerbi_reportid} className='insights-list'>
                              {this.reportsListItem(report)}
                            </div>)
                  }) : <div className='no-data-found'>{translatedErrorMessages.reportFilterEmptyData()}</div>
            }
          </div>
          <div className="reports-collapse-icon" onClick={() => this.props.collapseReportsList(true)}>
            <RiArrowLeftDoubleFill size="20px" color="#7800F7" />
          </div>
        </div>
        <div>{this.renameReportModal()}</div>
        <div>{this.duplicateReportModal()}</div>
        <div>{this.deleteReportModal()}</div>
        <div>{this.showProgressModal()}</div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    reportsData: state.reports.reportsData,
    reportsDataUpdated: state.reports.reportsDataUpdated,
    embeddedReportObj: state.embedReport.embeddedRepObj,
    isEditReportOn: state.editRepStatus.isEditReportOn,
    reportLoadStatus: state.embedReport.reportLoaded,
    loadingReportId: state.embedReport.loadingReportId,
    activeInsightReport: state.activeReport.activeInsightReport,
    adminUserData: state.admin.userData,
  }
}
  
const mapDispatchToProps = (dispatch) => {
  return {
    insightRepEditStatus: (val) => dispatch(insightRepEditStatus(val)),
    setActiveInsightReport: (val) => dispatch(activeInsightReport(val)),
    setReportsUpdateStatus: (status) => dispatch(setReportsUpdateStatus(status)),
    setFetchReportsDataStatus: (status) => dispatch(setFetchReportsDataStatus(status)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(InsightReports);
  
