import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
import { fetchTenantGroupProfile } from '../../services/TenantService';
import { updateGroupProfile, selectedTenantByAdmin } from '../../utilities/Utils';
import { setFetchReportsDataStatus, setReportsData } from '../../redux/actions/reportsActions';
import { updateActionStatus, activeInsightReport } from '../../redux/actions/headerActions';
import { ROUTE_ENDPOINTS } from '../../constant/RouteConstants';

import "../../styles/tenants.scss";
import Logo from "../../assets/logo.png";

function TenantsList(props) {
  const navigate = useNavigate();
  const [tenantsData, setTenantsData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTenants, setFilteredTenants] = useState([]);
  const [activeTenant, setActiveTenant] = useState(null);
  const [selectTenantInProgress, setSelectTenantInProgress] = useState(false);

  useEffect(() => {    
    const selectedTenantVal = selectedTenantByAdmin();
    if (selectedTenantVal) setActiveTenant(selectedTenantVal);
  }, [])

  useEffect(() => {
    if (props.adminUserData) {
      setTenantsData(props.adminUserData?.tenants);
    }
  }, [props.adminUserData])

  useEffect(() => {
    if (searchTerm) {
      const filteredData = tenantsData.filter(tenant =>
        tenant.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredTenants(filteredData);
    } else {
      setFilteredTenants(tenantsData);
    }
  }, [tenantsData, searchTerm])

  const onTenantSelection = (tenant) => {
    if (selectTenantInProgress === true) return;

    if (activeTenant && tenant === activeTenant) {
      navigate(ROUTE_ENDPOINTS.INSIGHTS);
    } else {
      handleTenantSelection(tenant);
    }
  }

  const handleTenantSelection = async (tenant) => {
    try {
      setSelectTenantInProgress(true);
      const response = await fetchTenantGroupProfile(tenant);
      if (response.status === 200 && response.data) {
        handleTenantSelectionSuccess(tenant, response.data);
      }
    } catch (err) {
      console.log(err);
      setSelectTenantInProgress(false);
    }
  }

  const handleTenantSelectionSuccess = async (tenant, profileData) => {
    setActiveTenant(tenant);
    updateGroupProfile(profileData);
    props.updateActionStatus({statusType: 'isAdminSelectedTenant', status: true});
    props.updateReportsData([]);
    props.setActiveInsightReport(null);
    props.setFetchReportsDataStatus(true);
    setTimeout(() => {
      setSelectTenantInProgress(false);
      navigate(ROUTE_ENDPOINTS.INSIGHTS);
    }, 300);
  }

  const Card = ({ tenantName }) => {
    const isActiveTenant = (activeTenant === tenantName);

    return (
      <div className={`tenant-card ${isActiveTenant ? 'active-tenant' : ''}`} onClick={() => onTenantSelection(tenantName)}>
        <h3>{tenantName}</h3>
      </div>
    );
  };

  const LoadingSpinner = () => {
    return (
      <div className='no-data-found'>
        <div>
          <Spinner animation="border" variant="primary">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      </div>
    )
  }  

  if (!tenantsData) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {tenantsData?.length ? (<div className='tenants-list'>
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="title">
          <h2>Select your customer</h2>
        </div>
        <div className="search-bar">
          <div className="search-wrapper">
            <input
              type="text"
              placeholder="Search customers"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <button
                onClick={() => setSearchTerm("")}
                className="clear-btn"
                aria-label="Clear Search"
              >
                <span aria-label="clear">x</span>
              </button>
            )}
          </div>
        </div>
        <div className="card-grid">
          <div className="tenants-container">
            {filteredTenants && filteredTenants.length > 0 ? (
              filteredTenants.map((tenant, index) => (
                <Card
                  key={index}
                  tenantName={tenant}
                />
            ))) : (
              <div className='no-events'>
              </div>
            )}
          </div>
        </div>
      </div>) : (
        <div className='no-data-found'>
          <h3>No Tenants Found</h3>
        </div>
      )}

      {(selectTenantInProgress) && (<LoadingSpinner />)}
    </>
  );
}
  
const mapStateToProps = (state) => {
  return {
    isAdminSelectedTenant: state.actionStatus.isAdminSelectedTenant,
    adminUserData: state.admin.userData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateActionStatus: (status) => dispatch(updateActionStatus(status)),
    setFetchReportsDataStatus: (status) => dispatch(setFetchReportsDataStatus(status)),
    updateReportsData: (data) => dispatch(setReportsData(data)),
    setActiveInsightReport: (val) => dispatch(activeInsightReport(val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TenantsList);
