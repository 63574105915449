import axios from 'axios';
import {  configTimers, baseApi } from '../constant/Constants';
import { getOktaAccessToken } from '../utilities/Utils';
import { STORAGE_KEYS } from "../constant/SessionConstants";

export const getUserDetails = () => {
  const oktaAccessToken = getOktaAccessToken();
  const apiKey = sessionStorage.getItem(STORAGE_KEYS.API_KEY);
  
  return axios.get(
    `${baseApi.smanUrl}/sman/users/${apiKey}`,
    {
      headers: {
          'Authorization': 'Bearer '+ oktaAccessToken,
          'Content-Type': 'application/json'
      }
    }, 
    {timeout: configTimers.insightsReqTimeout}
  )
}