import React from "react";
import { getGroupProfile } from "../../utilities/Utils";

function Logo() {
  const groupProfile = getGroupProfile();

  return (
    <div className="logo px-3">
      {groupProfile && groupProfile.agency && groupProfile.agency !== '' ? (
        <div className='logo-text'>
          {/* <span className='upper-text'>{groupProfile.agency}</span> */}
        </div>
      ) : ''}
    </div>
  );
}

export default Logo;