import imgUsDark from '../assets/US-Dark.png';
import imgUsDay from '../assets/US-Day.png';
import imgUsLight from '../assets/US-Light.png';
import imgUsNight from '../assets/US-Night.png';
import imgUsSatellite from '../assets/US-Satellite.png';
import imgMapStyle from '../assets/mapstyle_default.png';
import {STORAGE_KEYS} from "./SessionConstants";
import { authConfigs } from '../urls';
import i18n from 'i18next';

export const appName = 'SmartAnalytics';

export const appCode = 'sman';

export const smanAdminGroup = 'comtech_admin';

export const apiAuthParams = {
  okta: 'oauth',
  cognito: 'cauth',
}

export const baseApi = {
  url : sessionStorage.getItem(STORAGE_KEYS.BASE_URL),
  smanUrl: sessionStorage.getItem(STORAGE_KEYS.SMAN_BASE_URL),
}

export const serverUrls = {
  stylesUrl: `/geo/maps/v2/mb/styles/`,
  powerBiUrl: `https://api.powerbi.com/v1.0/myorg/groups/`
}

export const authConfig = authConfigs;

export const defaultZoom = '15';
  

export const mapStyle = {
  'images': {
    'US-Traffic-Satellite': imgUsSatellite,
    'US-Satellite': imgUsSatellite,
    'dark': imgUsDark,
    'day': imgUsDay,
    'light': imgUsLight,
    'night': imgUsNight,
    'default': imgMapStyle
  },
  'themes': {
    'dark' : 'dark',
    'day' : 'day',
    'light' : 'light',
    'night' : 'night'
  }
}

export const errorMessages = {
  mapUserMsg: 'errorMessages.mapUserMsg',
  loginFailedError: 'errorMessages.loginFailedError',
  sessionExpiredMsg: 'errorMessages.sessionExpiredMsg',
  reportAlreadyExist: 'errorMessages.reportAlreadyExist',
  userUpdateFailed: 'errorMessages.userUpdateFailed',
  duplicateReportError: 'errorMessages.duplicateReportError',
  deleteReportError: 'errorMessages.deleteReportError',
  renameReportError: 'errorMessages.renameReportError',
  reportSearchEmptyError: 'errorMessages.reportSearchEmptyError',
  reportFilterEmptyData: 'errorMessages.reportFilterEmptyData',
  selectOnePage: 'errorMessages.selectOnePage',
  reportNameEmpty: 'errorMessages.reportNameEmpty',
  selectOneToRename: 'errorMessages.selectOneToRename',
}

const labelMessages = {
  duplicateReport : 'labels.duplicateReport',
  reportNameHint :'labels.reportNameHint',
  reportGrpNameHint: 'labels.reportGrpNameHint',
  searchReport : 'labels.searchReport',
  duplicate : 'labels.duplicate',
  rename : 'labels.rename',
  cancel : 'labels.cancel',
  delete : 'labels.delete',
  ok : 'labels.ok',
  renameReport : 'labels.renameReport',
  selectAll: 'labels.selectAll',
  unselectAll: 'labels.unselectAll',
  reportGroup: 'labels.reportGroup',
  report: 'labels.report',
  selectAllReportsToDelete: 'labels.selectAllReportsToDelete',
}

// Function to generate translation functions
const generateTranslationFunctions = (messages) => {
  const translationFunctions = {};
  Object.keys(messages).forEach((key) => {
    translationFunctions[key] = () => {
      return i18n.t(messages[key]);
    };
  });
  return translationFunctions;
};

// Exporting translated error messages
export const translatedErrorMessages = generateTranslationFunctions(errorMessages);

export const translatedLableMessages = generateTranslationFunctions(labelMessages)

export const authConstants = {
  EMAIL: 'email',
  COMPANY: 'custom:company',
  FAMILY_NAME: 'family_name',
  GROUP_PROFILE: 'custom:group_profile',
}

export const featureLabels = {
  'map': 'FC_MAP',
  'traffic': 'FC_TRAFFIC',
  'weather': 'FC_WEATHER',
  'search': 'FC_SEARCH',
}

export const splitterConfigs = {
  secondaryPaneSize: '37%',
  dividerPosition: '50%',
  primaryPaneSize: '62.4%'
}

export const configTimers = {
  // When access token is expired, allow Amplify for 20 seconds to refresh for a new token.
  refreshTokenExpiryTime: 20000,
  // incident API timeout interval
  cancelStatusCode : 'ECONNABORTED',
  mapDataTimeout: 1000*30, // 30 seconds
  // This counter variable is used to check the Incidents max failure count.
  insightsReqTimeout: 1000 * 60,
}


export const locale = {
  us:'en-US',
  fr:'fr-FR',
  enCA: 'en-CA'
}

export const languageStr = {
  english:'English',
  french:'French'
}

export const langDateFormats = {
  [languageStr.english]: 'MM/DD/YYYY',
  [languageStr.french]: 'DD/MM/YYYY',
}

export const customReportType = 'Custom';

export const defaultReportType = 'Dashboard';

export const reportTypes = {
  DASHBOARD: 'Dashboard',
  STANDARD: 'Standard',
  AGENT: 'Agent',
  EVENT: 'Event',
  CUSTOM: 'Custom',
}

export const reportAccessRoles = {
  ADMIN: 'Admin',
  CONTRIBUTOR: 'Contributor',
  VIEWER: 'Viewer',
  LIMITED_VIEWER: 'LimitedViewer',
}

export const powerBiIdentities = {
  username: "InsightsPOCEmbed",
  roles: ["customdata"],
}

// When ever there is a change in Call types need to handle its revelant colors too
export const callTypesNames = {
  'wirelesstype' : "Wireless",
  'voiptype': 'VoIP',
  'landlinetype': 'Landline',
  'tddtype' : 'TDD',
  'rtttype': 'RTT',
  'smstype': 'Text',
  'tddchallenge': 'TDD Query',
  'notfoundtype': 'Not Found',
  'unknowntype': 'Unknown'
}

export const donutColorStatus = {
  'wirelesstype' : "#01b8aa",
  'voiptype': '#97DD40',
  'landlinetype': '#fd625e',
  'tddtype' : '#f2c80f',
  'rtttype': '#8ad4eb',
  'smstype': '#fe9666',
  'tddchallenge': '#0443e1',
  'notfoundtype': '#a66999',
  'unknowntype': '#3599b8'
}

export const AUTH_TYPES = {
  OKTA: 'okta',
  COGNITO: 'cognito',
}