import React, { useContext, useState, useEffect } from 'react';
import { MapContext } from '../../routes/MapContext';
import InsightReportsList from '../insights/InsightReportsList';
import InsightView from '../insights/InsightView';
import { RiArrowRightDoubleLine } from "react-icons/ri";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import  NetworkContext  from 'sa-common/context/NetworkContext';

function InsightsForm() {
    const { t } = useTranslation()
    const { isOnline } = useContext(NetworkContext);
    const [showReportView, setShowReportView] = useState(true);
    const [toggelReports, setToggleReports] = useState(false);
    const [repTypeToLoad, setReportTypeToLoad] = useState(null);
    let location = useLocation();

    useEffect(() => {
        if (isOnline && location.state && location.state.reportType) {
            setReportTypeToLoad(location.state.reportType);
            setToggleReports(false);
        }
    }, [location])

    useEffect(() => {
        if (repTypeToLoad) {
            setTimeout(() => setReportTypeToLoad(null), 1000);
        }
    }, [repTypeToLoad])

    const hideReportView = (bool) => {
        setShowReportView(bool)
    }

    const toggleReportsList = (toggleStatus) => {
        setToggleReports(toggleStatus);
    }

    return (
        <>
            <div className="reports-page">
                <div className={`reports-list-section ${toggelReports ? 'collapsed' : ''}`}>
                    <InsightReportsList
                        isOnline={isOnline}
                        reportTypeToLoad={repTypeToLoad} 
                        hideReportView={hideReportView} 
                        collapseReportsList={toggleReportsList} 
                    />
                </div>
                { !toggelReports ? (<div className="vertical-divider" />) : '' }
                <div className="report-view-section">
                    {showReportView ? <InsightView /> : <div className='no-data-found'>{t("errorMessages.no_records_found")}</div>}
                </div>
                {toggelReports ? (<div onClick={() => toggleReportsList(false)} className="reports-expand-icon">
                    <RiArrowRightDoubleLine size="20px" color="#7800F7" />
                </div>) : ''}
            </div>
        </>
      );


}
export default InsightsForm;