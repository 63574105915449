import React, { useState, useEffect, useRef, useContext } from 'react';
import { connect } from 'react-redux';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { insightRepEditStatus } from '../../redux/actions/headerActions';
import { setEmbeddedReportObj, setReportLoadedStatus, setFetchReportsDataStatus } from '../../redux/actions/reportsActions';
import { customReportType, reportAccessRoles } from '../../constant/Constants';
import { getUserLocaleCode, getUserDateFormatCode, getUserReportAccessRole } from '../../utilities/Utils'
import  NetworkContext  from 'sa-common/context/NetworkContext';

function InsightDashboard(props) {
	const { isOnline } = useContext(NetworkContext);
	const userRepAccessRole = getUserReportAccessRole();
	const [embeddedReport, setEmbeddedReport] = useState();
	const [isEmbedded, setIsEmbedded] = useState(false);
	const [embedReportKey, setEmbedReportKey] = useState(0);
	const embedRepObjRef = useRef(embeddedReport);
	
	// CSS Class to be passed to the embedded component
	const reportClass = 'report-container';

	// Pass the basic embed configurations to the embedded component to bootstrap the report on first load
  // Values for properties like embedUrl, accessToken and settings will be set on click of button
	const [embedConfig, setReportConfig] = useState({
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Embed,
		pageName: undefined,
		accessToken: undefined,
		settings: {
			panes: {
				pageNavigation: {
					visible: false,
				},
			},
			localeSettings: {
				language: getUserLocaleCode(), // Set the language/locale here
				formatLocale: getUserDateFormatCode() // Set the format locale if needed
			}
		},
		permissions: models.Permissions.All,
    viewMode: models.ViewMode.View,
		// viewMode: models.ViewMode.Edit,
	});

	/**
	 * Map of event handlers to be applied to the embedded report
	 * Update event handlers for the report by redefining the map using the setEventHandlersMap function
	 * Set event handler to null if event needs to be removed
	 */
	const[eventHandlersMap, setEventHandlersMap] = useState(new Map([
		['loaded', () => { handleReportLoad() }],
		['rendered', () => { handleRenderedEvent() }],
		['saved', () => { handleReportSave(embeddedReport) }],
		['visualClicked', () => console.log('visual clicked')],
		['dataSelected', (event) => { handleDataSelected(event) }],
		['error', (event) => {
				if (event) {
					// console.error(event.detail);
				}
			},
		],
	]));

  useEffect(() => {
		if (props.embedUrl && props.embedToken) embedReport();
	}, [props.embedUrl, props.embedToken]);

	useEffect(() => {
		if (isOnline && embeddedReport) {
			setTimeout(() => setEmbedReportKey(embedReportKey + 1), 5000);
		}
	}, [isOnline]);

	useEffect(() => {
		if (embeddedReport && (embeddedReport.config.id == props.reportID) && props.reportActivePage) {
			embeddedReport.setPage(props.reportActivePage);
		}
	}, [props.reportActivePage])

	useEffect(() => {
		if (embeddedReport) {
			switchEditMode();
		}
	}, [props.isEditReportOn]);

	useEffect(() => {
		if (props.fetchReportsDataStatus === true) {
			try {
				if (embedRepObjRef.current) {
					console.log("Refreshed");
					embedRepObjRef.current.refresh()
				}
			}
			catch (errors) {
				console.log(errors);
			}
		}
	}, [props.fetchReportsDataStatus])
	const handleReportLoad = () => {
		console.log('Report has been loaded');
		if (embedRepObjRef.current) {
			props.updateEmbeddedReportObj(embedRepObjRef.current); /* sets embedded report object in redux */
			props.updateReportLoadedStatus({reportLoaded: true, loadingReportId: embedRepObjRef.current.config.id});
		}
	}

	const handleRenderedEvent = () => {
		// console.log(' --- Report rendered ---')
	}

	const handleReportSave = () => {
		console.log('Report has been saved');
		embedRepObjRef.current.switchMode(models.ViewMode.View);
		props.insightRepEditStatus(false);
		props.setFetchReportsDataStatus(true);
	}

	const handleDataSelected = (event) => {
		// console.log(' --- Data selected ---')
	}

	const switchEditMode = () => {
		const allowedEditAccessRoles = [reportAccessRoles.CONTRIBUTOR, reportAccessRoles.ADMIN];

		if ((props.reportType !== customReportType) || (!allowedEditAccessRoles.includes(userRepAccessRole))) {
			props.insightRepEditStatus(false);
		} else {
			setReportConfig({
				...embedConfig,
				settings: {
					panes: {
						pageNavigation: {
							visible: props.isEditReportOn,
							// visible: false,
						},
					},
					localeSettings: {
						language: getUserLocaleCode(), // Set the language/locale here
						formatLocale: getUserDateFormatCode() // Set the format locale if needed
					}
				},
			});
			setIsEmbedded(true);
			embeddedReport.switchMode(props.isEditReportOn ? models.ViewMode.Edit : models.ViewMode.View);
		}
	}

	const embedReport = async () => {
		props.updateReportLoadedStatus({reportLoaded: false, loadingReportId: props.reportID});
		// Update the reportConfig to embed the PowerBI report
		setReportConfig({
			...embedConfig,
			embedUrl: props.embedUrl,
			pageName: props.reportActivePage,
			accessToken: props.embedToken,
			viewMode: props.reportMode,
			settings: {
				panes: {
					pageNavigation: {
						visible: false,
					},
				},
				localeSettings: {
					language: getUserLocaleCode(), // Set the language/locale here
					formatLocale: getUserDateFormatCode() // Set the format locale if needed
				}
			},
		});
		setIsEmbedded(true);
	};

	const reportComponent =
		<PowerBIEmbed
			key = { embedReportKey }
			embedConfig = { embedConfig }
			eventHandlers = { eventHandlersMap }
			cssClassName = { reportClass }
			getEmbeddedComponent = { (embedObject) => {
				embedRepObjRef.current = embedObject;
				setEmbeddedReport(embedObject); /* updates state variable "embeddedReport" */
			} }
		/>;

	return (
		<div className = "controls">
			{ isEmbedded ? reportComponent : null }
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		isEditReportOn: state.editRepStatus.isEditReportOn,
		embedToken: state.embedToken.embedToken,
		activeInsightReport: state.activeReport.activeInsightReport,
		fetchReportsDataStatus: state.reports.fetchReportsDataStatus,
		adminUserData: state.admin.userData,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		insightRepEditStatus: (val) => dispatch(insightRepEditStatus(val)),
		updateEmbeddedReportObj: (obj) => dispatch(setEmbeddedReportObj(obj)),
		updateReportLoadedStatus: (data) => dispatch(setReportLoadedStatus(data)),
		setFetchReportsDataStatus: (status) => dispatch(setFetchReportsDataStatus(status)),
	} 
}

export default connect(mapStateToProps, mapDispatchToProps)(InsightDashboard);