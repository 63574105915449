/* header actions */
export const FETCH_SPLIT_SCREEN = 'FTECH_SPLIT_SCREEN';
export const FETCH_MAP_THEME_REQUEST = 'FTECH_MAP_THEME_REQUEST';
export const FETCH_MAP_THEME_SUCCESS = 'FTECH_MAP_THEME_SUCCESS';
export const FETCH_MAP_THEME_FAIL = 'FTECH_MAP_THEME_FAIL';
export const FETCH_MAP_THEME_CANCEL = 'FTECH_MAP_THEME_CANCEL';
export const FETCH_MAP_THEME_URL = 'FETCH_MAP_THEME_URL';
export const FETCH_RENDER_THEME = 'FETCH_RENDER_Theme';
export const ACTIVE_INSIGHT_REPORT = 'ACTIVE_INSIGHT_REPORT';
export const EDIT_INSIGHT_REP_STATUS = 'EDIT_INSIGHT_REP_STATUS';
export const FTECH_SPLIT_SCREEN = 'FTECH_SPLIT_SCREEN';
export const SET_TAB_NAME = 'SET_TAB_NAME';
export const REPORTS_DATA = 'REPORTS_DATA';
export const REPORTS_DATA_UPDATED = 'REPORTS_DATA_UPDATED';
export const EMBED_TOKEN = 'EMBED_TOKEN';
export const FETCH_REPORTS_DATA = 'FETCH_REPORTS_DATA';
export const EMBEDDED_REPORT_OBJ = 'EMBEDDED_REPORT_OBJ';
export const REPORT_LOADED_STATUS = 'REPORT_LOADED_STATUS';
export const REFRESH_CALLS = 'REFRESH_CALLS';
export const ACTION_STATUS = 'ACTION_STATUS';
export const ADMIN_USER_DETAILS = 'ADMIN_USER_DETAILS';