import {
  REPORTS_DATA,
  REPORTS_DATA_UPDATED,
  EMBED_TOKEN,
  FETCH_REPORTS_DATA,
  EMBEDDED_REPORT_OBJ,
  REPORT_LOADED_STATUS,
} from '../actions/actionTypes';

const reportsDataState = { reportsData: [], reportsDataUpdated: false, fetchReportsDataStatus: false };
export const reportsDataReducer = (state = reportsDataState, action) => {
  switch (action.type) {
    case REPORTS_DATA:
      return {
        ...state,
        reportsData: action.data,
      }
    case REPORTS_DATA_UPDATED:
      return {
        ...state,
        reportsDataUpdated: action.status,
      }
    case FETCH_REPORTS_DATA:
      return {
        ...state,
        fetchReportsDataStatus: action.status,
      } 
    default:
      return state;
  }
}

const embedTokenState = { embedToken: null };
export const embedTokenReducer = (state = embedTokenState, action) => {
  switch (action.type) {
    case EMBED_TOKEN:
      return {
        ...state,
        embedToken: action.token,
      }
    default:
      return state;
  }
}

const embedReportObj = { embeddedRepObj: null, reportLoaded: false, loadingReportId: null };
export const embedReportReducer = (state = embedReportObj, action) => {
  switch (action.type) {
    case EMBEDDED_REPORT_OBJ:
      return {
        ...state,
        embeddedRepObj: action.object,
      }
    case REPORT_LOADED_STATUS:
      return {
        ...state,
        reportLoaded: action.data.reportLoaded,
        loadingReportId: action.data.loadingReportId,
      }
    default:
      return state;
  }
}