import React, { createContext, useState, useCallback, useRef } from 'react';

export const MapContext = createContext();
export const MapProvider = ({ children }) => {
  const [mapContainer, setMapContainer] = useState(null);
  const [mapLoadStatus, setMapLoadStatus] = useState(false);
  const mapRef = useRef(null);

  // Create a function to update mapContainer and mapRef
  const updateMapValues = useCallback((container, ref) => {
    console.log(":::: MapContext updateMapValues ::: ")
    setMapContainer(container);
    mapRef.current = ref;
  }, []);

  const updateMapLoadStatus = useCallback((status) => {
    setMapLoadStatus(status);
  }, []);
  return (
    <MapContext.Provider value={{ mapContainer, mapRef, mapLoadStatus, updateMapValues, updateMapLoadStatus }}>
      {children}
    </MapContext.Provider>
  );
};
