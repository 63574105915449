import axios from 'axios';
import {  configTimers, baseApi } from '../constant/Constants';
import { getOktaAccessToken, getApiKey } from '../utilities/Utils';
import { primaryRegionData, oktaUrls } from '../oktaexport-client';

export const fetchTenantGroupProfile = (tenantName) => {
  const apiUrl = primaryRegionData.createKongKeyAndUserAPI;
  const oktaAccessToken = getOktaAccessToken();

  const data = {
    appId: oktaUrls.CLIENT_ID,
    userKey: getApiKey(),
    tenantName: tenantName,
    handler: 'fetchTenantGroupProfile',
    authParam: 'oauth',
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${oktaAccessToken}`,
    },
  };

  return axios.post(apiUrl, data, config);
}

export const getTenantAgencies = (tenantName) => {
  const oktaAccessToken = getOktaAccessToken();
  const apiKey = getApiKey();
  const apiUrl = `${baseApi.smanUrl}/sman/tenants/psaps/${apiKey}?tenant=${tenantName}`;
  
  return axios.get(
    apiUrl,
    {
      headers: {
          'Authorization': 'Bearer '+ oktaAccessToken,
          'Content-Type': 'application/json'
      }
    }, 
    {timeout: configTimers.insightsReqTimeout}
  )
}