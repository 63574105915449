export const configTimers = {
  cancelStatusCode : 'ECONNABORTED',
  weatherReqTimeOut: 1000*5,  // 5 seconds
  trackingDataReqTime: 1000*15, // 15 seconds
  sessionTokenRetry:  1000*60, // 60 seconds
  searchCallTimeOut: 1000*60, // 60 seconds
  routeCallTimeOut: 1000*60, // 60 seconds
  reverseGeocodeTimeOut: 1000*60, // 60 seconds
  notificationSessionDuration: 43200, // duration in seconds (12 hours)
}
