import React, { useContext, useEffect, useState } from 'react';
import { MapContext } from '../../routes/MapContext';
import Map from "sa-common/map/Map";
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function MapView(props) {

    const { updateMapValues, updateMapLoadStatus } = useContext(MapContext);

    // Function to set mapContainer and mapRef when the map component is loaded
    const handleMapLoad = (container, ref) => {
        updateMapValues(container, ref);
        props.handleMapLoad(container, ref)
    };

    const handleMapLoadChange = (status) => {
        updateMapLoadStatus(status)
    }

    return (
        <>
            <Map
                mapStyle={props.mapStyle}
                zoom={props.zoom}
                centerCoordinates={props.center}
                onMapComponentLoaded={handleMapLoad}
                onMapLoadChange={handleMapLoadChange}
                searchValue={false}
            />
        </>
    )
}


const mapStateToProps = (state) => {
	return {
        
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
        
	} 
}

export default connect(mapStateToProps, mapDispatchToProps)(MapView);;