import React from 'react';
import ReactDOM from 'react-dom/client';
import { IconContext } from "react-icons";
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import Store from '../src/redux/store';
import '../src/styles/index.scss';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import locale_us from './locales/en-US.json'
import locale_fr from './locales/fr-CA.json'
import {getUserLocaleCode} from './utilities/Utils'
import {NetworkProvider} from "sa-common/context/NetworkContext";
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache'; 
import { configTimers } from './constant/Timers';

i18next.init({
	lng: 'auto',
	fallbackLng: getUserLocaleCode(),
	resources: {
		"en-US": {
			translation: locale_us,
		},
		'fr-FR': {
			translation: locale_fr,
		},
	},
	interpolation: { escapeValue: false },
})

const nonceValue = 'bXlzZWNyZXRub25jZTIx';
const cache = createCache({
    key:"nonce-key",
    nonce: nonceValue,
 });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <IconContext.Provider value={{ className: 'react-icons' }}>
    <Provider store={Store}>
      <I18nextProvider i18n={i18next}>
        <CacheProvider value={cache}>
			<NetworkProvider pingInterval={configTimers.pingInterval}>
      			<App />
			</NetworkProvider>
  		</CacheProvider>
      </I18nextProvider>
    </Provider>
  </IconContext.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
