import {
  ADMIN_USER_DETAILS,
} from '../actions/actionTypes';

const adminDataState = { userData: null };
export const adminDataReducer = (state = adminDataState, action) => {
  switch (action.type) {
    case ADMIN_USER_DETAILS:
      return {
        ...state,
        userData: action.data,
      }
    default:
      return state;
  }
}