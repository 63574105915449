import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getLoginToken } from "../utilities/Utils";
import { ROUTE_ENDPOINTS } from "../constant/RouteConstants";
import { isAdminGroupUser, selectedTenantByAdmin } from "../utilities/Utils";

export const PrivateRoute = ({ children }) => {
  const { pathname } = useLocation();
  const isLoggedIn = getLoginToken();

  if (!isLoggedIn) {
    return <Navigate to={{ pathname: ROUTE_ENDPOINTS.LOGIN }} />;
  }

  /* It redirects to /tenants page if user is an admin but hasn't selected a tenant yet */
  if (isLoggedIn && pathname !== ROUTE_ENDPOINTS.TENANTS && isAdminGroupUser() && !selectedTenantByAdmin()) {
    return <Navigate to={{ pathname: ROUTE_ENDPOINTS.TENANTS }} />;
  }

  /* It redirects to /insights (reports) page if user is not an admin but trying to access /tenants page */ 
  if (isLoggedIn && pathname === ROUTE_ENDPOINTS.TENANTS && !isAdminGroupUser()) {
    return <Navigate to={{ pathname: ROUTE_ENDPOINTS.INSIGHTS }} />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default PrivateRoute;