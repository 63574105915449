import AppRouter from "./routes/AppRouter";
import { useEffect, useContext } from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, Slide, toast } from 'react-toastify';
import '././styles/ReactToastify.css';
import { GlobalDebug } from "./utilities/removeConsoles";
import  NetworkContext  from 'sa-common/context/NetworkContext';
import { useTranslation } from 'react-i18next';


function App() {
  const toastId = "toast-id";
  const customToastId = "custom-toast-id";
  const { isOnline, isError } = useContext(NetworkContext);
  const { t } = useTranslation()


  useEffect(() => {
    (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "prod") &&
      GlobalDebug(true);
  }, []);

  useEffect(() => {
    if (!isOnline) {
      showNetworkError()
    } else if (isOnline && isError) {
      dismissNetworkError();
      if (isError == 'error') {
        toast.error(`${t("errorMessages.genericError")}`, { toastId: customToastId, autoClose: false });
      }
      else {
        toast.error(`${isError}`, {autoClose: false });
      }

    } 
    else if (isOnline && !isError) {
      dismissNetworkError();
    }
  }, [isError, isOnline]);

  const showNetworkError = () => {
    toast.dismiss();
    // if (toastId) {
    //   toast.update(toastId, { render: `${t("errorMessages.networkError")}`, type: toast.TYPE.ERROR, autoClose: false });
    // } else {
    //   toastId = toast.error(`${t("errorMessages.networkError")}`, { autoClose: false });
    // }
    toast.error(`${t("errorMessages.networkError")}`, { toastId: toastId, autoClose: false});
  }
  const dismissNetworkError = () => {
    toast.dismiss();
  }

  return (
    <div className="App">
      <AppRouter />
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        closeButton={false}
        transition={Slide}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      {/* {isOnline ?dismissNetworkError() : showNetworkError()} */}
    </div>
  );
}

export default App;
