import { Form, Row, Col } from 'react-bootstrap';
import '../../styles/profile.scss';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { STORAGE_KEYS } from "../../constant/SessionConstants";
import { langDateFormats } from "../../constant/Constants";
import { getUserDate, getUserLastLoginTime, hasUserAdminAccess, isAdminGroupUser } from "../../utilities/Utils";

function Profile() {
  const { t } = useTranslation()
  const userinfo = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.USER_INFO));
  const name = userinfo.given_name + ' ' + userinfo.family_name;
  const lastLogin = lastLoginDateTime();
  let psapNames = '';
  userinfo.agency.forEach(ag => {
    psapNames = psapNames ? (psapNames + ', ' + ag) : ag;
  });

  if (isAdminGroupUser() || hasUserAdminAccess()) {
    const tenantAgencies = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.TENANT_AGENCIES));
    psapNames = tenantAgencies.join(', ');
  }

  function lastLoginDateTime() {
    const lastLoginTS = getUserLastLoginTime();
    if (lastLoginTS) {
      const dateFormat = langDateFormats[getUserDate()] + ' ' + 'hh:mm A';
      return (lastLoginTS) ? (moment.unix(lastLoginTS).format(dateFormat)) : '';
    }
    return '';
  }

  return (
    <div className="content-section">
      <div className="user-profile-container">
        <h1>{t("profile.heading")}</h1>
        <div className="divider" />
        <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2} className="field-label">{t("profile.name")}</Form.Label>        
            <Col sm={6}>
              <Form.Control type="text" value={name} disabled />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2} className="field-label">{t("profile.lastLogin")}</Form.Label>        
            <Col sm={6}>
              <Form.Control type="text" value={lastLogin} disabled />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>{t("profile.language")}</Form.Label>
            <Col sm={6}>
              <Form.Control type="text" value={userinfo.language} disabled />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>{t("profile.timezone")}</Form.Label>
            <Col sm={6}>
              <Form.Control type="text" value={userinfo.zoneinfo} disabled />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>{t("profile.psapName")}</Form.Label>
            <Col sm={6}>
              <Form.Control type="text" value={psapNames} disabled />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>{t("profile.role")}</Form.Label>
            <Col sm={6}>
              <Form.Control type="text" className="capitalize" value={userinfo.report_access} disabled />
            </Col>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}

export default Profile;