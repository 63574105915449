import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { FaGlobeAmericas } from "react-icons/fa";
import { BsGearFill } from "react-icons/bs";
import { BiSolidDashboard } from "react-icons/bi";
import { MdBarChart, MdSupportAgent, MdEventNote } from "react-icons/md";
import 'sa-common/sidemenu/sidemenu.scss';
import '../../styles/sidemenu.scss';
import { ROUTE_ENDPOINTS } from '../../constant/RouteConstants';
import { reportTypes, featureLabels } from '../../constant/Constants';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { isFeatureAllowed } from '../../utilities/CommonUtils';

function SideMenu(props) {

    const location = useLocation();
    // Extract the pathname from the location object
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const [showReportIcons, setShowRepIcons] = useState(false);
    const [activeReportType, setActiveRepType] = useState(null);

    useEffect(() => {
        if (props.reportsData && props.reportsData.length) {
            setShowRepIcons(true);
        } else {
            setShowRepIcons(false);
        }
    }, [props.reportsData])

    useEffect(() => {
        if (props.activeInsightReport && props.activeInsightReport.report_type) {
            const activeRepType = (currentPath == ROUTE_ENDPOINTS.INSIGHTS) ? props.activeInsightReport.report_type : null;
            setActiveRepType(activeRepType);
        }
    }, [props.activeInsightReport])

    useEffect(() => {
        if (currentPath && currentPath !== ROUTE_ENDPOINTS.INSIGHTS) setActiveRepType(null);
    }, [currentPath])

    const activeRouteColor = ({ isActive }) => {
        return { color: isActive ? "#7800F7" : "#666666" };
        // return isActive;
    }

    const loadInsightReport = (reportType) => {
        if (reportType === reportTypes.CUSTOM) {
            navigate(ROUTE_ENDPOINTS.INSIGHTS);
        } else {
            navigate(ROUTE_ENDPOINTS.INSIGHTS, {state: { reportType: reportType }});
        }
    }

    const TooltipWrapper = ({ children, content }) => {
        return (
            <OverlayTrigger placement="right" overlay={(props) => renderTooltip(props, content)} delay={{show: 200, hide: 0}}>
                {children}
            </OverlayTrigger>
        )
    }

    const renderTooltip = (props, content) => (<Tooltip className="menu-tooltip" {...props}>{content}</Tooltip>)
    
    return (
        <>
            <div className="sideMenu">
                <div>
                    <TooltipWrapper content="Reports">
                        <NavLink to={ROUTE_ENDPOINTS.INSIGHTS} style={activeRouteColor} className="sideIcon reports">
                            <TbDeviceDesktopAnalytics />
                        </NavLink>
                    </TooltipWrapper>
                </div>
                {isFeatureAllowed(featureLabels.map) ? 
                <div>
                    <TooltipWrapper content="Call Info">
                        <NavLink to={ROUTE_ENDPOINTS.MAP} style={activeRouteColor} className="sideIcon callsInfo">
                            <FaGlobeAmericas />
                        </NavLink>
                    </TooltipWrapper>
                </div>
                : '' }
                <div>
                    <TooltipWrapper content="Settings">
                        <NavLink to={ROUTE_ENDPOINTS.SETTINGS} style={activeRouteColor} className="sideIcon">
                            <BsGearFill />
                        </NavLink>
                    </TooltipWrapper>
                </div>

                <div className="menu-divider" />

                { showReportIcons ? (
                    <>
                        <div>
                            <TooltipWrapper content="Dashboard Reports">
                                <span className="sideIcon" onClick={() => loadInsightReport(reportTypes.DASHBOARD)}>
                                    {activeReportType == reportTypes.DASHBOARD ? (<BiSolidDashboard className="report-icon icon-border active" />) : (<BiSolidDashboard className="report-icon icon-border" />)}
                                </span>
                            </TooltipWrapper>
                        </div>
                        <div>
                            <TooltipWrapper content="Standard Reports">
                                <span className="sideIcon" onClick={() => loadInsightReport(reportTypes.STANDARD)}>
                                    {activeReportType == reportTypes.STANDARD ? (<MdBarChart className="report-icon icon-border active" />) : (<MdBarChart className="report-icon icon-border" />)}
                                </span>
                            </TooltipWrapper>
                        </div>
                        <div>
                            <TooltipWrapper content="Agent Reports">
                                <span className="sideIcon callsInfo" onClick={() => loadInsightReport(reportTypes.AGENT)}>
                                    {activeReportType == reportTypes.AGENT ? (<MdSupportAgent size="28px" className="report-icon active" />) : (<MdSupportAgent size="28px" className="report-icon" />)}
                                </span>
                            </TooltipWrapper>
                        </div>
                        <div>
                            <TooltipWrapper content="Event Reports">
                                <span className="sideIcon callsInfo" onClick={() => loadInsightReport(reportTypes.EVENT)}>
                                    {activeReportType == reportTypes.EVENT ? (<MdEventNote size="28px" className="report-icon active" />) : (<MdEventNote size="28px" className="report-icon" />)}
                                </span>
                            </TooltipWrapper>
                        </div>
                    </>
                ) : ''}
            </div>
        </>
    );
};


const mapStateToProps = (state) => {
    return {
        reportsData: state.reports.reportsData,
        activeInsightReport: state.activeReport.activeInsightReport,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
       
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
