import {
  REPORTS_DATA,
  REPORTS_DATA_UPDATED,
  EMBED_TOKEN,
  FETCH_REPORTS_DATA,
  EMBEDDED_REPORT_OBJ,
  REPORT_LOADED_STATUS,
} from '../actions/actionTypes';

export const setReportsData = (reportsData) => {
  return {
    type: REPORTS_DATA,
    data: reportsData,
  }    
}

export const setReportsUpdateStatus = (status) => {
  return {
    type: REPORTS_DATA_UPDATED,
    status: status,
  }    
}

export const setEmbedToken = (data) => {
  return {
    type: EMBED_TOKEN,
    token: data,
  }    
}

export const setFetchReportsDataStatus = (status) => {
  return {
    type: FETCH_REPORTS_DATA,
    status: status,
  }    
}

export const setEmbeddedReportObj = (obj) => {
  return {
    type: EMBEDDED_REPORT_OBJ,
    object: obj,
  }
}

export const setReportLoadedStatus = (data) => {
  return {
    type: REPORT_LOADED_STATUS,
    data: data,
  }    
}