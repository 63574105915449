import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';


function AppCommon(props) {
  const location = useLocation();

  useEffect(() => {
    console.log(":::: App Common ::: ")
  }, []);

  return (<Outlet />);
};


// export default connect(mapStateToProps, mapDispatchToProps)(AppCommon);
export default AppCommon;