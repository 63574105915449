export const configTimers = {
  trackingDataReqTime: 1000*10, // 10 seconds
  mapDataTimeout: 1000*30, // 30 seconds
  // When access token is expired, allow Amplify for 20 seconds to refresh for a new token.
  refreshTokenExpiryTime: 20000,
  assignmentStatusReqTime: 1000 * 5, // 5 seconds
  assignmentCompleteWaitTime: 1000 * 30, // 30 seconds
  assignmentResendWaitTime: 30, // 30 seconds
  assignmentResendCloseTime: 30, // 30 seconds
  pingInterval: 5 * 60 * 1000, // 5 minutes
  sessionTokenRetry:  1000*60, // 60 seconds
  notificationSessionDuration: 43200, // duration in seconds (12 hours)
}
