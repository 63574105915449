import { combineReducers, createStore, applyMiddleware } from "redux";
import { thunk } from 'redux-thunk';
import { SplitScreenReducer, MapRenderReducer, MapThemesReducer, MapThemeUrlReducer, EditReportReducer, ActiveReportReducer, RefreshCallsReducer, actionStatusReducer } from "./reducers/headerReducer";
import { reportsDataReducer, embedTokenReducer, embedReportReducer } from "./reducers/reportsReducer";
import { adminDataReducer } from "./reducers/adminReducer";


const rootReducer = combineReducers({
  header: SplitScreenReducer,
  mapThemes: MapThemesReducer,
  mapUrl: MapThemeUrlReducer,
  mapRender: MapRenderReducer,
  editRepStatus: EditReportReducer,
  activeReport: ActiveReportReducer,
  reports: reportsDataReducer,
  embedToken: embedTokenReducer,
  embedReport: embedReportReducer,
  refreshApi: RefreshCallsReducer,
  actionStatus: actionStatusReducer,
  admin: adminDataReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;