import {
  FETCH_SPLIT_SCREEN,
  FETCH_MAP_THEME_REQUEST, 
  FETCH_MAP_THEME_SUCCESS, 
  FETCH_MAP_THEME_FAIL, 
  FETCH_MAP_THEME_URL, 
  FETCH_RENDER_THEME,
  FETCH_MAP_THEME_CANCEL,
  EDIT_INSIGHT_REP_STATUS,
  ACTIVE_INSIGHT_REPORT,
  REFRESH_CALLS,
  ACTION_STATUS,
} from '../actions/actionTypes';
import axios from 'axios';
import { serverUrls, translatedErrorMessages } from '../../constant/Constants';
import { configTimers } from '../../constant/Timers';
import { getBaseUrl, getApiKey, getUserName } from '../../utilities/Utils';

export const fetchSplitScreen = (splitScreen) => {
  return {
      type: FETCH_SPLIT_SCREEN,
      data: splitScreen
  }    
}

const fetchMapThemeRequest = () => {
  return {
      type: FETCH_MAP_THEME_REQUEST
  }
}

export const insightRepEditStatus = (val) => {
  return {
      type: EDIT_INSIGHT_REP_STATUS,
      data: val
  }    
}

export const activeInsightReport = (val) => {
  return {
      type: ACTIVE_INSIGHT_REPORT,
      data: val
  }    
}

const fetchMapThemeSuccess = (data) => {
  return {
      type: FETCH_MAP_THEME_SUCCESS,
      data: data
  }
}

const fetchMapThemeFail = (error) => {
  return {
      type: FETCH_MAP_THEME_FAIL,
      data: error
  }
}

const fetchMapThemeCancel = (val, retry) => {
  return {
      type: FETCH_MAP_THEME_CANCEL,
      error: val,
      retires: retry ? retry : false
  }
}

export const fetchMapThemeUrl = (url) => {
  return {
      type: FETCH_MAP_THEME_URL,
      data: url
  }
}

export const fetchRenderMapThemes = (val) => {
  return{
      type: FETCH_RENDER_THEME,
      data: val
  }
}

// let mapStyles = [] , callRetriesCount  = 0;
export const getMapThemesData = () => {
  let mapStyles = [] , callRetriesCount  = 0;

  return function (dispatch) {
    var gisStyleUrl = ""
    var defaultStyleUrl = getBaseUrl() + serverUrls.stylesUrl + 'default?api_key=' + getApiKey()
    // if (groupFeatures != null && groupFeatures.gis_id != null && groupFeatures.gis_id != "") {
    //     gisStyleUrl = serverUrls.serviceBaseUrl + 'styles/default?api_key=' + sessionStorage.getItem(STORAGE_KEYS.API_KEY)  + '&gis_id=' + groupFeatures.gis_id
    // }
    var customStylesUrl = getBaseUrl() + serverUrls.stylesUrl + getUserName() + '?api_key=' + getApiKey()

    const defaultStyleRequest = axios.get(defaultStyleUrl);
    const customStyleRequuest = axios.get(customStylesUrl);
    var finalUrl = []
    if (gisStyleUrl !== "") {
        const gisStyleRequest = axios.get(gisStyleUrl);
        finalUrl = [customStyleRequuest, gisStyleRequest, defaultStyleRequest]
    } else {
        finalUrl = [customStyleRequuest, defaultStyleRequest]
    }

    axios.all(finalUrl, { timeout: configTimers.mapDataTimeout })
    .then(axios.spread((...responses) => {
      if (responses && responses.length) {
        responses.map(styleData => {
          if (styleData && styleData.data && styleData.data.Resources) {
            mapStyles.push(...styleData.data.Resources)
          }
        })
      }
      let styleObjs = [];
      if (mapStyles.length) {
        mapStyles.forEach((style, ind) => {
          style['label'] = style.Name;
          style['value'] = style.Name
          styleObjs.push(style);
        });
        dispatch(fetchMapThemeCancel(null, false));
        dispatch(fetchMapThemeUrl(styleObjs[1].Url));
        dispatch(fetchMapThemeSuccess(styleObjs));
        dispatch(fetchRenderMapThemes(1));
        // dispatch(fetchRenderWeather(1));
        // dispatch(fetchRenderUncertainity(0));
      }
    }))
    .catch(error => {
      if (error.code === configTimers.cancelStatusCode) {
        callRetriesCount = callRetriesCount + 1;
        if (callRetriesCount > 2) {
            dispatch(fetchMapThemeCancel(translatedErrorMessages.mapUserMsg(), false));
            dispatch(fetchRenderMapThemes(1));
        }
        else {
            dispatch(fetchMapThemeCancel(null, true));
            dispatch(fetchRenderMapThemes(1));
        }
      }
      else if (error.response) {
        dispatch(fetchMapThemeCancel(error.response.statusText));
        dispatch(fetchRenderMapThemes(1));
      } else {
        dispatch(fetchMapThemeFail(error.message));
      }
    })
  }
}

export const refreshCalls = (val) => {
  return{
      type: REFRESH_CALLS,
      data: val
  }
}

export const updateActionStatus = (data) => {
  return {
    type: ACTION_STATUS,
    data: data
  } 
}