import axios from 'axios';
import { primaryRegionData } from '../oktaexport-client';

export const getOktaUserAPIKey = (applicationId, userId, username, groups, oktaAccessToken) => {
    const apiUrl = primaryRegionData.createKongKeyAndUserAPI;

    const data = {
        appId: applicationId,
        userId: userId,
        username: username,
        group: groups,
        isSaUser: true,
        handler: 'createUserAndUpdateinKong',
    };
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${oktaAccessToken}`,
        },
    };

    return axios.post(apiUrl, data, config);
}

export const updateGroupProfile = (applicationId, groupName, oktaAccessToken) => {
    const apiUrl = primaryRegionData.createKongKeyAndUserAPI;

    const data = {
        appId: applicationId,
        group: groupName,
        handler: 'updateGroupProfiles',
    };
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${oktaAccessToken}`,
        },
    };

    return axios.post(apiUrl, data, config);
}

export const updateUserProfile = (applicationId, userId, username, apiKey, oktaAccessToken) => {
    const apiUrl = primaryRegionData.createKongKeyAndUserAPI;

    const data = {
        appId: applicationId,
        userId: userId,
        username: username,
        apiKey: apiKey,
        handler: 'updateUserProfile',
        authType: 'oauth',
    };
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${oktaAccessToken}`,
        },
    };

    return axios.post(apiUrl, data, config);
}

export const getUserApplications = (userId, oktaAccessToken) => {
    const apiUrl = primaryRegionData.createKongKeyAndUserAPI;

    const data = {
        userId: userId,
        handler: 'getUserApplications',
        authType: 'oauth',
    };
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${oktaAccessToken}`,
        },
    };

    return axios.post(apiUrl, data, config);
}
