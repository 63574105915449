import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import './styles/map.scss';
import '../../node_modules/mapbox-gl/dist/mapbox-gl.css';
import './styles/geoservices-ctrl.css'
import './styles/doppler-control.css';
import comtechLogo from './assets/comtech-logo.svg'
import WeatherControl, { addRasterLayer, removeGifLayers, clearFrames } from './weather/WeatherControl';
import WeatherServices from '../services/WeatherServices';
import axios from 'axios';
import { configTimers } from '../constants/Timers'
import { serverUrls} from '../constants/Constants'
import {STORAGE_KEYS} from "../constants/SessionConstants"
import SearchAdd from '../search/SearchAdd'
import '../map/styles/markerPopup.scss'
import POI_Off from './assets/icon_poi_off.png';
import POI_On from './assets/icon_poi_on.png';

const Map = ({ mapStyle, zoom, onMapComponentLoaded, onMapLoadChange, searchValue, centerCoordinates }) => {
    const mapContainer = useRef();
    const map = useRef(null);
    let customControl;
    let cancelToken;
    let weatherService;
    const [search, setSearch] = useState('ADDRESS');
    const [showMap, setShowMap] = useState(false);

    useEffect(() => {
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapStyle,
            zoom,
            hash: true,
            center: centerCoordinates ? centerCoordinates : [-74.006, 40.7128]
        });
        map.current.addControl(new mapboxgl.ScaleControl({ unit: 'imperial' }), 'bottom-right');

        toggleShowCompass(true)
        toggleZoomButtons(true)
        map.current.once('styledata', onMaploaded);

        return () => {
            if (map.current) {
                map.current.remove();
            }
        };
    }, []);

    // Method to toggle zoom buttons
    const toggleZoomButtons = (showZoom) => {
        let options = { showCompass: false, showZoom: showZoom };
        console.log("::::::::::: toggleZoomButtons ::::::::: " + showZoom)
        map.current.addControl(new mapboxgl.NavigationControl(options), 'bottom-right');
    };

    // Method to toggle Compass buttons
    const toggleShowCompass = (enable) => {
        console.log("::::::::::: toggleShowCompass ::::::::: " + enable)
        let options = { showCompass: enable, showZoom: false };
        map.current.addControl(new mapboxgl.NavigationControl(options), 'top-right');
    };

    // Method to toggle Compass buttons
    const setMapCenter = (newCenter) => {
        console.log("::::::::::: setMapCenter ::::::::: " + newCenter)
        map.current.setCenter(newCenter);
    };

    const handleStyleSelection = (styleUrl) => {
        onMapLoadChange(false);
        map.current.once('style.load', onMaploaded);
        setTimeout(() => loadStyle(styleUrl), 100);
    }

    const loadStyle = (styleUrl) => {
        map.current.setStyle(styleUrl, { diff: false });
    }

    const onMaploaded = (e) => {
        let layerNames = map.current.getStyle().layers;
        console.log("::::::::::: onMaploaded ::::::::: ")
        // Pass the map object to the parent component
        onMapComponentLoaded(mapContainer.current, map.current);
        onMapLoadChange(true);
        // Add the custom methods to the mapContainer instance to make it accessible from outside the component
        if (mapContainer.current) {
            mapContainer.current.toggleZoomButtons = toggleZoomButtons;
            mapContainer.current.toggleShowCompass = toggleShowCompass;
            mapContainer.current.setMapCenter = setMapCenter;
            mapContainer.current.showWeatherLayers = showWeatherLayers;
            mapContainer.current.showTrafficLayers = showTrafficLayers;
            mapContainer.current.updateMapStyle = handleStyleSelection;
            mapContainer.current.getMapCenter = getMapCenter;
            mapContainer.current.createLngLatObj = createLngLatObj;
            mapContainer.current.drawMarker = drawMarker;
            mapContainer.current.calculateBoundsFromGeoJson = calculateBoundsFromGeoJson;
            
            setShowMap(true)
        }
        if (!document.getElementById('doppler-control'))
            customControl = new WeatherControl();

        weatherService = new WeatherServices();
        handleLatLngPopup(map.current, 'primary-map-latlng');
        removeGifLayers();
    }
    const handleLatLngPopup = (map, id) => {
        map.on('mousemove', (e) => {
            document.getElementById(id).style.display = 'block';
            document.getElementById(id).innerHTML = parseFloat(e.lngLat.lat).toFixed(6) + ', ' + parseFloat(e.lngLat.lng).toFixed(6);
        });
        map.on('mouseout', () => {
            document.getElementById(id).style.display = 'none';
        });
        map.on('touchmove', (e) => {
            document.getElementById(id).style.display = 'block';
            document.getElementById(id).innerHTML = parseFloat(e.lngLat.lat).toFixed(6) + ', ' + parseFloat(e.lngLat.lng).toFixed(6);
        });
        map.on('touchend', () => {
            document.getElementById(id).style.display = 'none';
        });
    }

    let weatherRetriesCount = 0
    const showWeatherLayers = (weather) => {
        if (weather) {
            //Check if there are any previous pending requests
            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel("Operation canceled due to new request.")
            }
            cancelToken = axios.CancelToken.source();
            let url = sessionStorage.getItem(STORAGE_KEYS.BASE_URL) + serverUrls.weatherUrl + `/doppler/1/0/0/radarFcst?ts=${Math.round(new Date().getTime() / 1000)}&api_key=` + sessionStorage.getItem(STORAGE_KEYS.API_KEY);
            weatherService.getForeCastTilePath(url, cancelToken).then(
                (response) => {
                    if (response.status == 200) {
                        (!document.getElementById("doppler-control")) ? map.current.addControl(customControl) : null;
                        addRasterLayer(Math.round(new Date().getTime() / 1000), 1, 0);
                    }
                }, error => {
                    if (error.code == configTimers.cancelStatusCode) {
                        weatherRetriesCount = weatherRetriesCount + 1;
                        if (weatherRetriesCount > 2) {
                            map.current.removeControl(customControl);
                            removeGifLayers();
                            weatherRetriesCount = 0
                        } else {
                            showRadarLayers(weather)
                        }
                    } else {
                        // handleError(error, errorMessages.suggestionError);
                    }
                }
            );
        } else {
            map.current.removeControl(customControl);
            removeGifLayers();
        }
    };

    const showTrafficLayers = (traffic) => {
        if (map.current && map.current != null) {
            var layers = map.current.getStyle().layers;
            if (layers) {
                for (var ind in layers) {
                    if (layers[ind].source == 'traffic') {
                        map.current.setLayoutProperty(layers[ind].id, 'visibility', traffic ? 'visible' : 'none')
                    }
                }
            }
        }
    }

   const getMapCenter = () => {
        return map.current ? map.current.getCenter(): '';
    }

   const createLngLatObj = (location) => {
    let lngLat = new mapboxgl.LngLat(location.coordinates[0].longitude, location.coordinates[0].latitude);
        return lngLat;
    }
   const drawMarker = (options, lngLat) => {
       return new mapboxgl.Marker(options).setLngLat(lngLat).addTo(map.current);        
    }

    const calculateBoundsFromGeoJson = (geojsonData) => {
        return geojsonData.features.reduce((bounds, feature) => {
            bounds.extend(feature.geometry.coordinates);
            return bounds;
        }, new mapboxgl.LngLatBounds());
    }

    return <>
    { searchValue && (
        <div className='search-container'> 
            <SearchAdd 
                mapCenter={getMapCenter}
                map={map.current} 
                showMap={showMap} 
            />              
            <div className="searchLayout">
            <button className="ssbtn" style={{ background: search === 'ADDRESS' ? '#4b4b4b' : '#d1d1d1' }}>{search === 'ADDRESS' ? <img src={POI_On} className='poi-icon' /> : <img src={POI_Off} className='poi-icon' />}</button>           
            </div>
        </div> 
    )}
        <div ref={mapContainer} style={{ width: '100%', height: '100%' }}>
            <div className="comtech-logo">
                <a href="https://www.comtechlocation.com/location-products/location-studio/geolocation-solutions" target='_blank' ><img src={comtechLogo} /></a>
            </div>
            <pre id="primary-map-latlng" className="latlng-popup"></pre>
        </div>
    </>;
};

export default Map;