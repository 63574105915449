import React, { useState } from 'react';
import InsightDashboard from "./InsightDashboard";
import '../../styles/insightsview.scss';
import { connect } from 'react-redux';

function InsightsView (props){
    const [visualData, setvisualData] = useState(null);

   const  handleVisualDataSelection = (eventData) => {
    setvisualData(visualData =  eventData )
  }

  return (
    <div className="insights-view">
      {(props.activeInsightReport) ? <InsightDashboard
            reportID={props.activeInsightReport.powerbi_reportid}
            embedUrl={props.activeInsightReport.embed_url}
            reportType={props.activeInsightReport.report_type}
            reportActivePage={props.activeInsightReport.activePage}
            reportMode={props.activeInsightReport.reportMode}
            handleVisualDataSelection={handleVisualDataSelection}
          /> : ''}
    </div>
  );
}
const mapStateToProps = (state) => {
	return {
    activeInsightReport: state.activeReport.activeInsightReport,    
	}
}

export default connect(mapStateToProps)(InsightsView);