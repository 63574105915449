import * as turfHelper from '@turf/helpers';
import booleanIntersects from '@turf/boolean-intersects';
import { getGroupProfile } from "./Utils";
import { STORAGE_KEYS } from "../constant/SessionConstants";
import { donutColorStatus, languageStr } from "../constant/Constants";

var turfPoint = require("turf-point");

export const prepareClustersGeojson = (device, propsObjCallback) => {
  return {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: device.geometry && device.geometry.coordinates && device.geometry.coordinates.length ? swipeCoordinates(device) : []
    },
    properties: propsObjCallback(device)
  }
}

const swipeCoordinates = (obj) => {
  return [
    obj.geometry.coordinates[obj.geometry.coordinates.length - 1][1],
    obj.geometry.coordinates[obj.geometry.coordinates.length - 1][0]
  ]
}

export const UpdatePrepareClustersGeojson = (device, propsObjCallback) => {
  return {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: device && device.coordinates && device.coordinates.length ? swipeUpdateCoordinates(device) : []
    },
    properties: propsObjCallback(device)
  }
}

const swipeUpdateCoordinates = (obj) => {
  return [
    obj.coordinates[1],
    obj.coordinates[0]
  ]
}

export const createDonutChart = (props) => {
  const offsets = [];
  const counts = [
    props.wirelessType,
    props.voipType,
    props.landLineType,
    props.TDDType,
    props.RTTType,
    props.SMSType,
    props.TDDChalType,
    props.notFoundType,
    props.unknownType
  ];
  const colors = [donutColorStatus.wirelesstype, donutColorStatus.voiptype, donutColorStatus.landlinetype, 
    donutColorStatus.tddtype, donutColorStatus.rtttype, donutColorStatus.smstype, 
    donutColorStatus.tddchallenge, donutColorStatus.notfoundtype, donutColorStatus.unknowntype]
  let total = 0;
  for (const count of counts) {
    offsets.push(total);
    total += count;
  }
  const fontSize =
    total >= 50 ? 12 : total >= 20 ? 11 : total >= 10 ? 11 : 10;
  const r =
    total >= 50 ? 32 : total >= 20 ? 24 : total >= 10 ? 18 : 14;
  const r0 = Math.round(r * 0.6);
  const w = r * 2;

  let html = `<div>
  <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle"; font: ${fontSize}px sans-serif;>`;

  for (let i = 0; i < counts.length; i++) {
    html += donutSegment(
      offsets[i] / total,
      (offsets[i] + counts[i]) / total,
      r,
      r0,
      colors[i]
    );
  }
  html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
  <text dominant-baseline="central" transform="translate(${r}, ${r})">
      ${total.toLocaleString()}
  </text>
  </svg>
  </div>`;

  var el = document.createElement('div');
  el.innerHTML = html;
  return el.firstChild;
}

export const donutSegment = (start, end, r, r0, color) => {
  if (end - start === 1) end -= 0.00001;
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0),
    y0 = Math.sin(a0);
  const x1 = Math.cos(a1),
    y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;

  // draw an SVG path
  return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0
    } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1
    } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0
    }" fill="${color}" />`;
}

export const calculateUpdateMarkerBBbox = (list, map) => {
  let isInside = false;
  if (map && list && list.geometry.coordinates && list.geometry.coordinates.length) {
    // Get the map's current bounds
    const bounds = map.getBounds();
    // Convert the bounds to a Turf.js polygon feature
    const mapPolygonObj = turfHelper.polygon([[
      [bounds.getWest(), bounds.getSouth()],
      [bounds.getEast(), bounds.getSouth()],
      [bounds.getEast(), bounds.getNorth()],
      [bounds.getWest(), bounds.getNorth()],
      [bounds.getWest(), bounds.getSouth()]
    ]]);

    var point = turfPoint([list.geometry.coordinates[list.geometry.coordinates.length - 1][1], list.geometry.coordinates[list.geometry.coordinates.length - 1][0]]);
    isInside = booleanIntersects(mapPolygonObj, point);
    // if (isInside) {
    //     consoleLog(':::: marker update within the bounding box');
    // } else {
    //     consoleLog(':::: marker update is not within the bounding box');
    // }
  }
  return isInside;
}

export function isFeatureAllowed(feature) {
  const groupProfile = getGroupProfile();
  if (!feature || !groupProfile || !groupProfile.features) {
    return false;
  }

  return groupProfile.features.includes(feature);
}


export function handleSaveSettings(language, dateFormat) {
  const currentUser = sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER);
  var temp = [];
  let userSettingsList = localStorage.getItem(STORAGE_KEYS.USERSETTINGS);
  if (userSettingsList !== null && userSettingsList != '') {
    temp = [...JSON.parse(userSettingsList)]
    const index = temp.findIndex(item => ((item.userName === currentUser)));
    if(index >= 0) {
      temp[index].userName = currentUser;
      temp[index].language = language;
      temp[index].dateFormat = dateFormat;
    } else {
      temp.push({
        userName: currentUser,
        language: language,
        dateFormat: dateFormat,
      })
    }
  } else {
    temp.push({
      userName: currentUser,
      language: language,
      dateFormat: dateFormat,
    })
  }
  localStorage.setItem(STORAGE_KEYS.USERSETTINGS, JSON.stringify(temp))  

}