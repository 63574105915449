import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchMapThemeUrl, getMapThemesData, refreshCalls } from '../../redux/actions/headerActions';
import { Spinner } from "react-bootstrap";
import { MapContext } from '../../routes/MapContext';
import { getCallInfo } from '../../services/CallsServices'
import { useTranslation } from "react-i18next";
import { FaSearch, FaTimes } from 'react-icons/fa';
import '../../styles/callInfo.scss'
import { callTypesNames, defaultZoom, donutColorStatus, featureLabels } from "../../constant/Constants";
import mapboxgl from 'mapbox-gl';
import { createDonutChart, isFeatureAllowed } from '../../utilities/CommonUtils';
import { toast } from "react-toastify";
import { createRoot } from 'react-dom/client';
import { useNavigate } from "react-router-dom";
import { ROUTE_ENDPOINTS } from "../../constant/RouteConstants";
import moment from "moment";
import  NetworkContext  from 'sa-common/context/NetworkContext';
import { STORAGE_KEYS } from '../../constant/SessionConstants';

const wirelesStatusExp = ['==', ['get', 'carrieridtype'], ['literal', callTypesNames.wirelesstype]];
const VOPIStatusExp = ['==', ['get', 'carrieridtype'], ['literal', callTypesNames.voiptype]];
const LandlineStatusExp = ['==', ['get', 'carrieridtype'], ['literal', callTypesNames.landlinetype]];
const TDDStatusExp = ['==', ['get', 'carrieridtype'], ['literal', callTypesNames.tddtype]];
const RTTStatusExp = ['==', ['get', 'carrieridtype'], ['literal', callTypesNames.rtttype]];
const SMSStatusExp = ['==', ['get', 'carrieridtype'], ['literal', callTypesNames.smstype]];
const TDDCHALStatusExp = ['==', ['get', 'carrieridtype'], ['literal', callTypesNames.tddchallenge]];
const notFoundStatusExp = ['==', ['get', 'carrieridtype'], ['literal', callTypesNames.notfoundtype]];
const unknownStatusExp = ['==', ['get', 'carrieridtype'], ['literal', callTypesNames.unknowntype]];
let callInfoList = {}
// objects for caching and keeping track of HTML marker objects (for performance)
var markers = {};
let markersOnScreen = {};
let incidentPopup, donutPopup;
let displayCallInfoList = []
const incidentsSource = "incidents"
const incidentCircleLayer = "incident-circle"
const toastId = "toast-id";
var donuts = [];
var featureData = {
  type: "FeatureCollection",
  features: []
};
function CallInfoComponent(props) {
  const { t } = useTranslation();
  const { isOnline, isError } = useContext(NetworkContext);
  const navigate = useNavigate();
  const { mapContainer, mapRef, mapLoadStatus } = useContext(MapContext);
  const [searchValue, setSearchVal] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [showApiSpinner, setApiShowSpinner] = useState(false);
  const [showApiError, setApiError] = useState(null);
  const [featureCollection, setFeatureCollection] = useState([]);
  const [carrierTypeCollection, setCarrierTypeCollection] = useState([]);

  useEffect(() => {
    if(isFeatureAllowed(featureLabels.map)) {
      if (props.mapUrl && !mapContainer && !mapRef.current && isOnline) {
        const styleUrl = props.mapUrl
        props.fetchMapThemeUrl('');
        props.fetchMapThemeUrl(styleUrl);
      } else if (!mapContainer && !mapRef.current) {
        props.getMapThemesData();
      }
      if(isOnline)
        getCallHistoryData()
    } else {
      navigate(ROUTE_ENDPOINTS.LOGIN)
    }
  }, [isOnline])
  
  useEffect(() => {
    if (!isOnline) {
      console.log(`::: No internet :::`)
    } else if (isOnline && isError) {
      console.log(`::: Error but internet is active :::`)
    } else if (isOnline && !isError) {
      console.log(`isOnline :::: ${isOnline} ::: isError :::: ${isError}`)
    }
  }, [isError, isOnline]);

  useEffect(() => {
    if(props.refreshApi){
      removeDonuts();
      getCallHistoryData();
    }
  }, [props.refreshApi])

  useEffect(() => {
    if (mapContainer && mapRef.current) {
      // Map is ready, you can use mapContainer and mapRef safely here
      setTimeout(() => {
        console.log("::: Call Info: Map is ready :::: ")
        mapRef.current.on('moveend', () => {
          updateCarrierCounts()
        });
      }, 1000);
    }
  }, [mapContainer, mapRef.current]);

  // useEffect(() => {
  //   setFeatureCollection([]);
  //   if (mapContainer && mapRef.current && mapLoadStatus) {
  //     let tempFC = featureCollection;
  //     setTimeout(() => {
  //     if(mapLoadStatus){
  //       setFeatureCollection(tempFC);
  //     }
  //   },500);      
  //   }
  // }, [mapLoadStatus])

  useEffect(() => {
    return () => {
      removeDonuts();
      setFeatureCollection([]);
    };
  }, []);

  useEffect(() => {
    if (mapRef && mapRef.current && featureCollection.length && mapLoadStatus && isOnline) {
      console.log("featureCollection ::: ", featureCollection.length);
      addClusters();
      updateMarkers(featureCollection);
      mapRef.current.on('render', () => {
        if (!mapRef.current.getSource(incidentsSource) || !mapRef.current.isSourceLoaded(incidentsSource))
          return;
        updateMarkers(featureCollection);
      });
    }
  }, [featureCollection, mapLoadStatus, isOnline])

  useEffect(() => {
    if (isOnline && mapRef && mapRef.current && mapLoadStatus) {
      if (props.trafficStatus) mapContainer.showTrafficLayers(true);
      if (props.weatherStatus) mapContainer.showWeatherLayers(true);
    }
  }, [mapLoadStatus])

  const getCallHistoryData = () => {
    var formattedStartOfDay, formattedEndOfDay;
    var userinfo = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.USER_INFO));
    if (userinfo && userinfo.group_timezone) {
      var timeZoneDate = moment.tz(userinfo.group_timezone).format('YYYY-MM-DD');
      console.log(`current time in ${userinfo.group_timezone} is ${moment.tz(userinfo.group_timezone).format('YYYY-MM-DDTHH:mm:ss[Z]')}`);
      // Set the start and end of the day in the user's timezone
      var startOfDay = moment.tz(`${timeZoneDate}T00:00:00`, userinfo.group_timezone);
      var endOfDay = moment.tz(`${timeZoneDate}T23:59:59`, userinfo.group_timezone);
      // Convert the start and end of the day to UTC
      formattedStartOfDay = startOfDay.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      formattedEndOfDay = endOfDay.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      console.log('Formatted Start of Day in UTC:', formattedStartOfDay);
      console.log('Formatted End of Day in UTC:', formattedEndOfDay);
    } else {
      // Get the start and end of the day in localtime
      var zonedStartDate = moment().startOf('day');
      var zonedEndDate = moment().endOf('day');
      formattedStartOfDay = zonedStartDate.format('YYYY-MM-DDTHH:mm:ss[Z]');
      formattedEndOfDay = zonedEndDate.format('YYYY-MM-DDTHH:mm:ss[Z]');
      console.log(formattedStartOfDay, formattedEndOfDay);
    }
    setApiShowSpinner(true);
    setApiError(null);
    getCallInfo(formattedStartOfDay, formattedEndOfDay).then(response => {
      setApiShowSpinner(false)
      if (response && response.status === 200) {
        if (response.data && response.data.count > 0 && response.data.records) {
          setFeatureCollection([]);
          response.data.records = response.data.records.map((record) => {
            var newProperties = { ...record.properties }; // Create a copy of properties
            newProperties.carrieridtype = getCallType(newProperties); // Add callType property
            newProperties.carrieridtypevalues = getCallTypeValues(newProperties); // Add callType values property
            return { ...record, properties: newProperties }; // Update record with new properties
          });
          response.data.records = response.data.records.filter(function (el) {
            return el.geometry && el.geometry.coordinates && el.geometry.coordinates.length && el.properties.carrieridtype;
        })
          callInfoList = response.data;
          displayCallInfoList = response.data.records;
          setFeatureCollection(response.data.records);
          setCarrierTypeCollection(response.data.records);
          featureData.features = response.data.records;
        } else {
          toast.warning(t("errorMessages.noDataFound"), { toastId: toastId });
        }
      } else {
        toast.warning(t("errorMessages.noDataFound"), { toastId: toastId });
      }
    })
      .catch(error => {
        setApiShowSpinner(false)
        
        console.log('error: ', error);
        if(error.response && error.response.data && error.response.data.error) {
          setApiError(error.response.data.error)
        }
        else if(error.response && error.response.data) {
          setApiError(error.response.data)
        } else if(error.message){
          setApiError(error.message)
        } else {
          setApiError(t("errorMessages.callsError"))
        }
      });
  }

  // Function to determine callType based on property values
  const getCallType = (properties) => {
    if (properties.wirelesstype === 1) {
      return callTypesNames.wirelesstype
    } else if (properties.voiptype === 1) {
      return callTypesNames.voiptype
    } else if (properties.landlinetype === 1) {
      return callTypesNames.landlinetype
    } else if (properties.tddtype === 1) {
      return callTypesNames.tddtype
    } else if (properties.rtttype === 1) {
      return callTypesNames.rtttype
    } else if (properties.smstype === 1) {
      return callTypesNames.smstype
    } else if (properties.tddchallengetype === 1) {
      return callTypesNames.tddchallenge
    } else if (properties.notfoundtype === 1) {
      return callTypesNames.notfoundtype
    } else if (properties.unknowntype === 1) {
      return callTypesNames.unknowntype
    } else {
      return null
    }
  }

  // Function to capture multiple calltypes values based on property values
  const getCallTypeValues = (properties) => {
    const callTypeVals = [];
    for (const property in callTypesNames) {
      const propkey = (property === 'tddchallenge') ? 'tddchallengetype' : property;
      if (properties[propkey] === 1) {
        callTypeVals.push(callTypesNames[property]);
      }
    }
    return callTypeVals;
  }

  const handleTextChange = (event) => {
    if (event.target.value.length > 0) {
      setSearchVal(event.target.value);
    } else {
      clearSearchText()
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      // this will remove the focus from input field
      // if (event.target) {
      //   event.target.blur();
      // }
      if (callInfoList && callInfoList.count && callInfoList.count > 0 && searchValue && searchValue.trim() !== "") {
        setShowSpinner(true)
        setTimeout(() => {
          handleSearchFilter();
        }, 100);
      }
    }
  };

  const handleSearchFilter = () => {
    const filtered = callInfoList.records.filter(
      (record) => record.properties.callingnumber.includes(searchValue)
    );
    setShowSpinner(false)
    setFeatureCollection(filtered)
    displayCallInfoList = filtered
    if (filtered.length === 0)
      toast.warning(t("errorMessages.noDataFound"), { toastId: toastId });
  }

  const clearSearchText = () => {
    setSearchVal('');
    setShowSpinner(false)
    if (callInfoList && callInfoList.count > 0 && callInfoList.records) {
      displayCallInfoList = callInfoList.records
      setFeatureCollection(callInfoList.records);
    } else {
      displayCallInfoList = []
      setFeatureCollection([]);
    }
  }

  const updateMarkers = () => {
    const newMarkers = {};
    const features = mapRef.current.querySourceFeatures(incidentsSource);
    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (const feature of features) {
      const coords = feature.geometry.coordinates;
      const props = feature.properties;
      if (!props.cluster) continue;
      const id = props.cluster_id;

      let marker = markers[id];
      if (!marker) {
        const el = createDonutChart(props);
        el.addEventListener('mouseenter', function (e) {
          e.target.style.cursor = "pointer";
          addDonutPopup(props, coords, featureData.features);
        })
        el.addEventListener('mouseleave', function (e) {
          e.target.style.cursor = "";
          removeDonutPopup();
        })
        el.addEventListener('click', (e) => {
          mapRef.current.getSource(incidentsSource).getClusterExpansionZoom(
            id,
            (err, zoom) => {
              if (err) return;

              mapRef.current.easeTo({
                center: coords,
                zoom: zoom
              });
              removeDonutPopup();
            }
          );
          el.addEventListener('mouseleave', function (e) {
            e.target.style.cursor = "";
            removeDonutPopup();
          })
        })
        mapRef.current.on('zoom', () => {
          removeDonutPopup();
        })
        mapRef.current.on('click', incidentsSource, addClusters);
        marker = markers[id] = new mapboxgl.Marker({
          element: el
        }).setLngLat(coords);
      }
      donuts.push(marker);
      newMarkers[id] = marker;

      if (!markersOnScreen[id]) marker.addTo(mapRef.current);
    }
    // for every marker we've added previously, remove those that are no longer visible
    for (const id in markersOnScreen) {
      if (!newMarkers[id]) markersOnScreen[id].remove();
    }
    markersOnScreen = newMarkers;
  }

  const addClusters = () => {
    let clusterJson = {
      'type': 'FeatureCollection',
      'features': featureCollection
    }

    const incidentsSourceExists = mapRef.current.getSource(incidentsSource);
    if (incidentsSourceExists) {
      // Update the existing source's data
      incidentsSourceExists.setData(clusterJson);
    } else if (featureCollection.length) {
      mapRef.current.addSource(incidentsSource, {
        'type': 'geojson',
        'data': clusterJson,
        'cluster': true,
        'clusterRadius': 30,
        'clusterProperties': {
          // keep separate counts for each magnitude category in a cluster
          'wirelessType': ['+', ['case', wirelesStatusExp, 1, 0]],
          'voipType': ['+', ['case', VOPIStatusExp, 1, 0]],
          'landLineType': ['+', ['case', LandlineStatusExp, 1, 0]],
          'TDDType': ['+', ['case', TDDStatusExp, 1, 0]],
          'RTTType': ['+', ['case', RTTStatusExp, 1, 0]],
          'SMSType': ['+', ['case', SMSStatusExp, 1, 0]],
          'TDDChalType': ['+', ['case', TDDCHALStatusExp, 1, 0]],
          'notFoundType': ['+', ['case', notFoundStatusExp, 1, 0]],
          'unknownType': ['+', ['case', unknownStatusExp, 1, 0]],
          "clusterIds": ["concat", ["concat", ["get", "id"], ","]]
        }
      });

      mapRef.current.addLayer({
        'id': incidentCircleLayer,
        'type': 'circle',
        'source': incidentsSource,
        'filter': ['!=', 'cluster', true],
        'paint': {
          'circle-color': [
            'case',
            wirelesStatusExp,
            donutColorStatus.wirelesstype,
            VOPIStatusExp,
            donutColorStatus.voiptype,
            LandlineStatusExp,
            donutColorStatus.landlinetype,
            TDDStatusExp,
            donutColorStatus.tddtype,
            RTTStatusExp,
            donutColorStatus.rtttype,
            SMSStatusExp,
            donutColorStatus.smstype,
            TDDCHALStatusExp,
            donutColorStatus.tddchallenge,
            notFoundStatusExp,
            donutColorStatus.notfoundtype,
            unknownStatusExp,
            donutColorStatus.unknowntype,
            donutColorStatus.unknowntype
          ],
          'circle-opacity': 1,
          'circle-radius': 10
        }
      });
      mapRef.current.on('mouseenter', incidentCircleLayer, (e) => {
        showIncidentPopup(featureCollection, incidentCircleLayer, e);
        mapRef.current.getCanvas().style.cursor = "pointer";
      });
      mapRef.current.on('mouseleave', incidentCircleLayer, () => {
        mapRef.current.getCanvas().style.cursor = '';
        removeIncidentPopup();
      });
    }

    if (featureCollection.length) {
      setBounds(featureCollection)
    }
  }

  const updateCarrierCounts = () => {
    if (mapRef && mapRef.current) {
      // Get the bounding box of the visible area
      const bounds = mapRef.current.getBounds();

      // Filter the GeoJSON data to include only points within the bounding box
      const visiblePoints = displayCallInfoList.filter(feature => {
        var coordinates;
        if(feature.geometry.coordinates && feature.geometry.coordinates.length){
          coordinates = feature.geometry.coordinates;
          return bounds.contains(coordinates);
        }
      });
      setCarrierTypeCollection(visiblePoints)
    }
  }
  
  const getSubstituteRecords = (searchValue, callList) => {
    if (searchValue && searchValue.trim() !== "") {
      return callList && callList.length
        ? callList
        : [];
    } else {
      return callList && callList.length
        ? callList
        : [];
    }
  }

  const populateCount = (type) => {
    const records = carrierTypeCollection.length > 0 ? carrierTypeCollection : getSubstituteRecords(searchValue, carrierTypeCollection);
    // Early return if records are not available 
    if (records.length === 0) {
      return 0;
    }
    if (type === callTypesNames.Other) {
      const otherTypes = Object.values(callTypesNames).filter(val => val !== callTypesNames.Other);
      const filtered = records.filter(record => !otherTypes.includes(record.properties.carrieridtype));
      return filtered.length;
    } else {
      const filtered = records.filter(record => (record.properties.carrieridtypevalues.includes(type)));
      return filtered.length;
    }
  }

  const setBounds = (featuresList) => {
    if (featuresList.length > 0) {
      const bounds = mapContainer.calculateBoundsFromGeoJson({ type: 'FeatureCollection', features: featuresList });
      // Fit the map bounds 
      mapRef.current.fitBounds(bounds, {
        maxZoom: defaultZoom,
        padding: { top: 120, bottom: 180, left: 50, right: 75 },
        duration: 0
      });
    }
  }

  const showIncidentPopup = (data, layerName, e) => {
    let features = mapRef.current.queryRenderedFeatures(e.point, { layers: [layerName] });
    let feature = features[0];
    let incidents = getIncidents(data, feature.properties.id);
    var coordinates = incidents.length ? incidents[0].geometry.coordinates : null;
    if (coordinates) {
      if (incidentPopup)
        incidentPopup.remove();

      incidentPopup = new mapboxgl.Popup({
        offset: [0, -8], closeButton: false, closeOnClick: false, className: `incident-popup`
      });
      var popupHTML = `<div>
        <div class='status-box'>
          ${feature.properties.callingnumber ? `<div><span class='call-info-label'>Calling Number:</span> ${feature.properties.callingnumber}</div>` : ''}
          <div><span class='call-info-label'>Latitude:</span> ${parseFloat(coordinates[1]).toFixed(6)}</div>
          <div><span class='call-info-label'>Longitude:</span> ${parseFloat(coordinates[0]).toFixed(6)}</div>
          <div><span class='call-info-label'>Type of Call:</span> ${feature.properties.carrieridtype}</div>
          <div><span class='call-info-label'>Call Time:</span> ${feature.properties.calltime}</div>
        </div>
      </div>`;
      incidentPopup
        .setLngLat(incidents[0].geometry.coordinates)
        .setHTML(popupHTML)
        .addTo(mapRef.current);
    }
  }

  const removeIncidentPopup = () => {
    if (incidentPopup)
      incidentPopup.remove();
  }

  const getIncidents = (data, id) => {
    let incidentData = data.filter(x => (
      x.properties.id == id
    ));

    return incidentData;
  }

  const addDonutPopup = (donut, coords, features) => {
    let incidents = [], callTypes = [];
    let clusterIdsList = donut.clusterIds.replace(/[, ]+$/, "").trim();
    let filterIdsList = clusterIdsList.split(',');
    features.filter(function (el) {
      filterIdsList.map(id => {
        if (el.properties && el.properties.id == id) {
          incidents.push(el);
          callTypes.push(el.properties.carrieridtype);
        }
      })
    })
    callTypes = [...new Set(callTypes)];
    if (donutPopup) {
      donutPopup.remove();
    }
    donutPopup = new mapboxgl.Popup({ offset: [0, -12], closeButton: false, closeOnClick: false, className: `cluster-popup` })
      .setLngLat(coords)
      .setDOMContent(addPopupContent(incidents, callTypes));
    donutPopup.addTo(mapRef.current);
  }

  const addPopupContent = (data, callTypes) => {
    let incidentObj = [];
    callTypes.map(type => {
      let carrieridtypeList = [];
      carrieridtypeList = data.filter(function (el) {
        return type === el.properties.carrieridtype;
      })
      data.filter(function (el) {
        if (el.properties.carrieridtype === type) {
          incidentObj.push({ 'type': type, 'typeCount': carrieridtypeList })
        }
      })
      incidentObj = [...new Map(incidentObj.map(item => [item['type'], item])).values()];
    })
    const placeholder = document.createElement('div');
    const jsx = <div className='donut-popup'>
      <h4>Calls</h4>
      {
        incidentObj.map((obj) => {
          let callTypeColor;
          if (obj.type == callTypesNames.wirelesstype) {
            callTypeColor = donutColorStatus.wirelesstype;
          }
          else if (obj.type == callTypesNames.voiptype) {
            callTypeColor = donutColorStatus.voiptype;
          }
          else if (obj.type == callTypesNames.landlinetype) {
            callTypeColor = donutColorStatus.landlinetype;
          }
          else if (obj.type == callTypesNames.tddtype) {
            callTypeColor = donutColorStatus.tddtype;
          }
          else if (obj.type == callTypesNames.rtttype) {
            callTypeColor = donutColorStatus.rtttype;
          }
          else if (obj.type == callTypesNames.smstype) {
            callTypeColor = donutColorStatus.smstype;
          }
          else if (obj.type == callTypesNames.tddchallenge) {
            callTypeColor = donutColorStatus.tddchallenge;
          }
          else if (obj.type == callTypesNames.notfoundtype) {
            callTypeColor = donutColorStatus.notfoundtype;
          }
          else if (obj.type == callTypesNames.unknowntype) {
            callTypeColor = donutColorStatus.unknowntype;
          }
          return (
            <div key={obj.type} >
              <div className='d-flex justify-content-between align-items-center text-capitalize'>
                <span className='lable-type'>{obj.type}:</span>
                <div className=' count-list'>
                  <div style={{ color: callTypeColor }}>{obj.typeCount.length}</div>
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
    var root = createRoot(placeholder);
    root.render(jsx);
    return placeholder
  }

  const removeDonutPopup = () => {
    if (donutPopup) {
      donutPopup.remove();
    }
  }

  const removeDonuts = () => {
    if(donuts.length){
      donuts.map(donut => {
          donut.remove();
      })
      removeIncidentLayers();
  }
  markers = {};
}
  
  const removeLayer = (layerName) => {
      if (mapRef.current.getLayer(layerName)) {
          mapRef.current.removeLayer(layerName);

      }
  }

  const removeSource = (layerName) => {
      if (mapRef.current.getSource(layerName)) {
          mapRef.current.removeSource(layerName);

      }
  }

  const removeIncidentLayers = () => {
    let incidentSource = mapRef.current.getSource(incidentsSource);
    if (typeof incidentSource) {
      removeLayer(incidentCircleLayer);
      removeSource(incidentsSource);
    }
  }

  const renderCallTypes = () => {
    return Object.keys(callTypesNames).map((key, index) => (
      <div key={key}>
        <div style={{ color: donutColorStatus[key] }}>{populateCount(callTypesNames[key])}</div>
        <div className='call-type'>{callTypesNames[key]}</div>
      </div>
    ));
  };

  
  return (
    <>
      <div className='calls-info-panel'>
        {isFeatureAllowed(featureLabels.search) ? <div className="mobile-search-box">
          <input
            type="text"
            placeholder={t("callsInfo.searchHint")}
            value={searchValue}
            onChange={handleTextChange}
            onKeyDown={handleKeyDown}
          />
          {showSpinner ?
            <Spinner animation="border" variant="primary" size="sm" className="search-spinner" />
            : searchValue !== '' && !showSpinner ?
              <FaTimes className='icon hand-cursor' onClick={() => { clearSearchText() }}>close</FaTimes>
              : <FaSearch className="icon" onClick={() => { handleSearchFilter() }} />
          }
        </div> : ''}
        {showApiSpinner ? '' : showApiError ? '' : <div className='call-list-count'>
          <div className='d-flex justify-content-between'>
            <div>
              <div>{carrierTypeCollection.length ? carrierTypeCollection.length : 0}</div>
              <div className='call-type'>{t("callsInfo.today")}</div>
            </div>
             {renderCallTypes()}
          </div>
        </div>}
        {showApiSpinner ? <div className='loading-spinner'>
          <Spinner animation="border" variant="primary" />
          <div>{t("labels.gettingData")}</div>
        </div> : ''}
        {isOnline && showApiError && !isError ? <div className='calls-error'>
          <div>{showApiError ? showApiError : ''}</div>
        </div> : ''}
        <div title="Locate" className='bounds-icon call-bounds-icon hand-cursor' onClick={() => { setBounds(featureCollection) }} ></div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    mapThemes: state.mapThemes ? state.mapThemes.data : state.mapThemes,
    mapUrl: state.mapUrl ? state.mapUrl.mapUrl : state.mapUrl,
    refreshApi: state.refreshApi ? state.refreshApi.refreshApi : state.refreshApi,
    trafficStatus: state.actionStatus.trafficStatus,
    weatherStatus: state.actionStatus.weatherStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMapThemeUrl: (url) => dispatch(fetchMapThemeUrl(url)),
    getMapThemesData: () => dispatch(getMapThemesData()),
    refreshCalls: (val) => dispatch(refreshCalls(val))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallInfoComponent);