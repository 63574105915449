import * as React from "react";
import "../../styles/footer.css";
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation()
  return (
    <footer id='footer' className='footer footer-fix'>
      <div className="navbar-header position-relative container">
        <div className="d-flex">
            <div>
              {t("footer.copy_rights")}
            </div>
            <div className="footer-links">
              <span className="link">
                <a
                  href="https://comtech.com/privacy-policies"
                  target="_blank"
                >
                  {t("footer.privacy_policy")}
                </a>
              </span>
              <span className="link">
                <a
                  href="https://comtech.com/terms-of-use"
                  target="_blank"
                >
                  {t("footer.terms_of_use")}
                    </a>
              </span>
              <span className="last-link">
                <a
                  href="https://www.comtechlocation.com/contact-us"
                  target="_blank"
                >
                 {t("footer.contact_us")}
                </a>
              </span>
            </div>
        </div>
      </div>
    </footer>
  );
}
