import axios from 'axios';
import {  configTimers, baseApi, serverUrls, powerBiIdentities } from '../constant/Constants';
import { getOktaAccessToken, isAdminGroupUser, getTenantName, hasUserAdminAccess } from '../utilities/Utils';
import { STORAGE_KEYS } from "../constant/SessionConstants";

export const getCallInfo = (start, end) => {
  const oktaAccessToken = getOktaAccessToken();
  const apiKey = sessionStorage.getItem(STORAGE_KEYS.API_KEY);
  let apiUrl = `${baseApi.smanUrl}/sman/datasource/callinfo/${apiKey}?starttime=${start}&endtime=${end}`;

  if (isAdminGroupUser() || hasUserAdminAccess()) {
    apiUrl += `&tenant=${getTenantName()}`;
  }

  return axios.get(
    apiUrl,
    {
      headers: {
          'Authorization': 'Bearer '+ oktaAccessToken,
          'Content-Type': 'application/json'
      }
    }
  )
}