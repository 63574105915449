import React, { createContext, useState, useEffect, useRef } from 'react';

const NetworkContext = createContext({ isOnline: true });

export const NetworkProvider = ({ children, pingInterval = 5 * 60 * 1000, offlinePingInterval = 30000}) => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [isError, setIsError] = useState(null);
  const bearerToken = useRef(null); // Store current token
  const pingUrl = useRef(null); 
  let timeoutId = null
  const abortControllerRef = useRef(null);
  const [currentPingUrl, setCurrentPingUrl] = useState(null);

  const handleConnectivityChange = () => {
    setIsOnline(window.navigator.onLine);
    if(window.navigator.onLine){
      setIsError(null)
    }
  };

  const updatePingToken = (newToken) => {
    console.log("Ping Token Time ::: ", new Date())
    bearerToken.current = newToken
  };

  const setPingUrl = (url) => {
    console.log("Ping Token Time ::: ", new Date())
    pingUrl.current = url
    setCurrentPingUrl(url);
  };

  const pingServer = async () => {
    try {
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

       timeoutId = setTimeout(() => {
        if(abortControllerRef && abortControllerRef.current)
          abortControllerRef.current.abort();
          console.log('Ping timed out');
      }, 61000); // Set timeout after 60 seconds

      const headers = new Headers();
      if (pingUrl.current) { // if bearerToken is not their then dont make api call
        // headers.append('Authorization', `Bearer ${bearerToken.current}`);
        const response = await fetch(pingUrl.current, { signal, headers });

        clearTimeout(timeoutId); // Clear timeout if response received

        if (response.ok) {
          // setIsOnline(true);
          setIsError(null)
        } else {
          console.log('Ping failed:', response.statusText);
        }
      }
    } catch (error) {
      if(!isOnline) {
        return
      }
      if(error.message){
        setIsError(error.message)
      } else { 
        setIsError("error")
      } 
      // setIsOnline(false); // Assume offline on other errors
    } finally {
      clearTimeout(timeoutId);
      abortControllerRef.current = null;
    }
  };

  useEffect(() => {
    window.addEventListener('online', handleConnectivityChange);
    window.addEventListener('offline', handleConnectivityChange);
    let intervalId
    if(pingUrl.current) {
        // Initial ping to check initial state
      pingServer();
      intervalId = setInterval(pingServer,  isOnline ? pingInterval : offlinePingInterval);
    }
    // Cleanup function to remove event listeners on unmount
    return () => {
      window.removeEventListener('online', handleConnectivityChange);
      window.removeEventListener('offline', handleConnectivityChange);
      clearTimeout(timeoutId);
      clearInterval(intervalId);
      abortControllerRef.current = null;
    };
  }, [isOnline, pingInterval, offlinePingInterval, currentPingUrl, isError]);

  return (
    <NetworkContext.Provider value={{ isOnline, updatePingToken, setPingUrl, isError }}>{children}</NetworkContext.Provider>
  );
};

export default NetworkContext;