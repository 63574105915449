export const STORAGE_KEYS = {
    GROUP_PROFILE: 'groupProfile',
    CURRENT_USER: 'currentUser',
    CURRENT_REGION: 'currentRegion',
    USER: 'user',
    API_KEY: 'api_key',
    ID_TOKEN: 'idToken',
    ACCESS_TOKEN: 'accessToken',
    GROUP_NAME: 'groupName',
    BASE_URL: 'base',
    END_CALL_TIME: 'endCallTime',
    USER_INFO: 'userinfo',
    OKTA_TOKENS: 'oktaTokens',
    TENANT_NAME: 'tenantName',
    AGENCY: 'agency',
    SMAN_BASE_URL: 'smanBaseUrl',
    USERSETTINGS: 'userSettings',
    USER_DATE_FORMAT: 'dateFormat',
    USER_LANG: 'userLang',
    USER_APPLICATIONS: 'userApplications',
    USER_LAST_LOGIN: 'userLastLogin',
    TENANT_AGENCIES: 'tenantAgencies',
}
