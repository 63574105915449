export const urls = {
	debuggLogs: true,
	statusCheckUrl: 'https://api.dev.smartresponse.net',
};

export const oktaUrls = {
	APP_NAME: 'ComtechAnalytics',
	OKTA_BASE_URL: 'https://state911.oktapreview.com',
	CLIENT_ID: '0oabflsef3AwWHBNA1d7',
	ISSUER: '/oauth2/auscvecx6xnFHLbyO1d7',
};

export const primaryRegionData = {
	baseUrl:'https://api.dev.smartresponse.net',
	smanBaseUrl: 'https://apis-sman.dev.smartresponse.net',
	createKongKeyAndUserAPI: 'https://apis-sman.dev.smartresponse.net/oauth/CreateKongKeyAndUserInSmanDb',
	aws_project_region: "us-west-2",
};

export const secondaryRegionData  = {
	baseUrl:'https://api.dev2.smartresponse.net',
	smanBaseUrl: 'https://apis-sman.dev.smartresponse.net',
	createKongKeyAndUserAPI: '',
	aws_project_region: "us-east-1",
};