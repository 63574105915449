import { Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../styles/settings.scss";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { languageStr, langDateFormats } from "../../constant/Constants";
import { handleSaveSettings } from "../../utilities/CommonUtils";
import { ROUTE_ENDPOINTS } from "../../constant/RouteConstants";
import { getUserLocaleCode, getUserDate,  getUserLanguage, selectedTenantByAdmin, isAdminGroupUser} from "../../utilities/Utils";

function Settings() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(languageStr.english);
  const [dateFormat, setDateFormat] = useState(languageStr.english);

  useEffect(() => {
    setLanguage(getUserLanguage())
    setDateFormat(getUserDate())
  }, []);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleDateFormatChange = (e) => {
    setDateFormat(e.target.value);
  };

  const saveSettings = (e) => {
    e.preventDefault();
    handleSaveSettings(language, dateFormat);
    i18n.changeLanguage(getUserLocaleCode());
  };

  return (
    <div className="content-section">
      <div className="settings-container">
        <h1>{t("settings.heading")}</h1>
        <div className="divider" />
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col} xs={3}>
              <Form.Label>{t("settings.language")}</Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs={5}>
              <Form.Control
                as="select"
                value={language}
                onChange={handleLanguageChange}
                className="custom-select"
              >
                <option value={languageStr.english}>{t("settings.english")}</option>
                <option value={languageStr.french}>{t("settings.french")}</option>
              </Form.Control>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} xs={3}>
              <Form.Label>{t("settings.dateformat")}</Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs={5}>
              <Form.Control
                as="select"
                value={dateFormat}
                onChange={handleDateFormatChange}
                className="custom-select"
              >
                <option value={languageStr.english}>{langDateFormats[languageStr.english]}</option>
                <option value={languageStr.french}>{langDateFormats[languageStr.french]}</option>
              </Form.Control>
            </Form.Group>
          </Row>
          {isAdminGroupUser() && (
            <Row className="mb-3">
              <Form.Group as={Col} xs={3}>
                <Form.Label>Tenant</Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={5}>
                <InputGroup className="mb-3">
                  <Form.Control
                    value={selectedTenantByAdmin()}
                    readOnly
                  />
                  <Button variant="outline-secondary" onClick={() => navigate(ROUTE_ENDPOINTS.TENANTS)}>
                    Change
                  </Button>
                </InputGroup>
              </Form.Group>
            </Row>
          )}
          <div className="col-xs-12 col-sm-8 d-flex align-items-end justify-content-end">
            <button className="btn btn-primary save-btn" onClick={saveSettings}>
              {t("settings.save")}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Settings;
