import axios from 'axios';
import {  configTimers, baseApi, serverUrls, powerBiIdentities } from '../constant/Constants';
import { getOktaAccessToken, getUserLocaleCode, getGroupProfile, isAdminGroupUser, getTenantName, hasUserAdminAccess } from '../utilities/Utils';
import { STORAGE_KEYS } from "../constant/SessionConstants";

export const getInsightReports = () => {
  const oktaAccessToken = getOktaAccessToken();
  const apiKey = sessionStorage.getItem(STORAGE_KEYS.API_KEY);
  let apiUrl = `${baseApi.smanUrl}/sman/reports/${apiKey}`;

  if (isAdminGroupUser() || hasUserAdminAccess()) {
    apiUrl += `?tenant=${getTenantName()}`;
  }

  return axios.get(
    apiUrl,
    {
      headers: {
          'Authorization': 'Bearer '+ oktaAccessToken,
          'Content-Type': 'application/json'
      }
    }, 
    {timeout: configTimers.insightsReqTimeout}
  )
}

export const getEmbedToken = () => {
  const oktaAccessToken = getOktaAccessToken();
  const apiKey = sessionStorage.getItem(STORAGE_KEYS.API_KEY);
  let apiUrl = `${baseApi.smanUrl}/sman/embedtoken/${apiKey}`;

  if (isAdminGroupUser() || hasUserAdminAccess()) {
    apiUrl += `?tenant=${getTenantName()}`;
  }

  return axios.get(
    apiUrl,
    {
      headers: {
          'Authorization': 'Bearer '+ oktaAccessToken,
          'Content-Type': 'application/json'
      }
    }, 
    {timeout: configTimers.insightsReqTimeout}
  )
}

export const duplicateInsightReport = (reportId, reportName) => {
  const oktaAccessToken = getOktaAccessToken();
  const apiKey = sessionStorage.getItem(STORAGE_KEYS.API_KEY);
  const url = `${baseApi.smanUrl}/sman/reports/duplicate/${apiKey}`;
  const data = {
    source_report_id: reportId,
    target_report_name: reportName,
  };
  const config = {
    headers: {
      'Authorization': 'Bearer '+ oktaAccessToken,
      'Content-Type': 'application/json'
    },
  };

  return axios.post(url, data, config);
}

export const deleteInsightReport = (reportId) => {
  const oktaAccessToken = getOktaAccessToken();
  const apiKey = sessionStorage.getItem(STORAGE_KEYS.API_KEY);
  const url = `${baseApi.smanUrl}/sman/reports/${reportId}/${apiKey}`;
  const config = {
    headers: {
      'Authorization': 'Bearer '+ oktaAccessToken,
      'Content-Type': 'application/json'
    },
  };

  return axios.delete(url, config);
}

export const renameInsightReport = (reportId, reportName) => {
  const oktaAccessToken = getOktaAccessToken();
  const apiKey = sessionStorage.getItem(STORAGE_KEYS.API_KEY);
  const url = `${baseApi.smanUrl}/sman/reports/rename/${reportId}/${apiKey}`;
  const data = {
    target_report_name: reportName,
  };
  const config = {
    headers: {
      'Authorization': 'Bearer '+ oktaAccessToken,
      'Content-Type': 'application/json'
    },
  };

  return axios.put(url, data, config);
}

export const getPBIAccessToken = () => {
  const apiKey = sessionStorage.getItem(STORAGE_KEYS.API_KEY);
  const oktaAccessToken = getOktaAccessToken();
  let apiUrl = `${baseApi.smanUrl}/sman/accesstoken/${apiKey}`;

  if (isAdminGroupUser() || hasUserAdminAccess()) {
    apiUrl += `?tenant=${getTenantName()}`;
  }

  return axios.get(
    apiUrl,
    {
      headers: {
          'Authorization': 'Bearer '+ oktaAccessToken,
          'Content-Type': 'application/json'
      }
    }
  )
}

export const getExportId = (accessToken, reportId, groupId, format, activeReport) => {
  const url = `${serverUrls.powerBiUrl}${groupId}/reports/${reportId}/ExportTo`;
  const datasetId = activeReport.datasetid;
  const userinfo = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.USER_INFO));
  let agencyes = '|' + userinfo.agency.join('|') + '|';

  if (isAdminGroupUser() || hasUserAdminAccess()) {
    const tenantAgencies = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.TENANT_AGENCIES));
    agencyes = '|' + tenantAgencies.join('|') + '|';
  }
  
  const data = {
    format: format,
    powerBIReportConfiguration: {
      "settings": {
        "locale": getUserLocaleCode(),
      },
      "pages": [{
        "pageName" : activeReport.activePageData.Name
      }],
      "identities": [{
        "username": powerBiIdentities.username,
        "roles": powerBiIdentities.roles,
        "customData": agencyes,
        "datasets": [datasetId]
      }
      ]
    }
  };
  const config = {
    headers: {
      'Authorization': 'Bearer '+ accessToken,
      'Content-Type': 'application/json'
    },
  };
  return axios.post(url, data, config);
}

export const getExportStatus = (accessToken, reportId, groupId, exportId) => {
  const url = `${serverUrls.powerBiUrl}${groupId}/reports/${reportId}/exports/${exportId}`;
  const config = {
    headers: {
      'Authorization': 'Bearer '+ accessToken,
      'Content-Type': 'application/json'
    },
  };
  return axios.get(url, config);
}